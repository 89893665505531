import React from "react";
import { Grid, Box } from "@mui/material";
import { useMediaQuery } from "react-responsive";

export default function RegisterConfirm(props) {

    const isMobile = useMediaQuery({
        query: '(max-device-width: 900px)',
    });

    return (
        <Grid
            className="login-text"
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                height: '60vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '1.5rem'
            }}
        >
            <Box
                justifyContent="center"
                direction="column"

                alignItems={"center"}
                sx={{
                    width: "100%",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    fontSize: isMobile ? "1.5rem" : "2rem"
                }}
            >
                Confirmation email sent to {props.email}
            </Box>
        </Grid >
    )
}