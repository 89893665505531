import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import './css/TrustapIncoming.css'

export default function TrustapPayment() {

    const [status, setStatus] = React.useState()
    const location = useLocation();

    let navigate = useNavigate();


    const server = process.env.REACT_APP_SERVER_ADDRESS

    React.useEffect(() => {
        // Parse the query string
        const searchParams = new URLSearchParams(location.search);
        const params = {};
        for (const [key, value] of searchParams) {
            params[key] = value;
        }


        fetch(`${server}/trustap/accept?transaction_id=${params.tx_id}`, {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        })
            .then(resp => {
                if (resp.ok) {
                    // If the response is ok, set status to 'ok'
                    setStatus("ok");
                    return resp.json();
                } else {
                    // If the response is not ok, set status to 'failed'
                    setStatus("failed");
                    throw new Error('Network response was not ok.');
                }
            })

        //setGivenParams(params);
    }, [location]);


    const redirectTimer = () => {
        setTimeout(() => {
            navigate('/')
        }, 5000)
    }

    React.useEffect(() => {
        redirectTimer();
    }, []);


    if (status == "ok") {
        return (
            <div className="trustap-login-text">
                Payment successful - you will now be redirected
            </div>
        )
    }
    else if (status == "failed") {
        return (
            <div className="trustap-login-text">
                Payment unsuccessful - you will now be redirected
            </div>
        )
    }
    else {
        return (
            <div className="trustap-login-text">
                Checking registration status
            </div>
        )
    }
}
