import React from "react";
import { Dialog, Grid, Button } from "@mui/material";
import './dealprofile.css'

export default function TrustapDialog(props) {

    const [complete, setComplete] = React.useState();

    const userId = sessionStorage.getItem('user_id')
    const server = process.env.REACT_APP_SERVER_ADDRESS

    const [userData, setUserData] = React.useState(undefined)

    React.useEffect(() => {
        fetch(`${server}/user/userdetails`, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body:
                JSON.stringify({
                    id: userId
                })
        })
            .then(resp => resp.json())
            .then(data => setUserData(data.data))
            .then(console.log(userData))
    }, [])

    React.useEffect(() => {
        console.log('userdata', userData)
    }, [userData])


    const baseUrl = "https://sso.trustap.com/auth/realms/trustap-stage/protocol/openid-connect/auth";
    const clientId = "355dfef2-3d30-486e-a371-df556e63a3b9";
    const clientSecret = "d17eef18-6540-4ffe-8030-064f474aa487";
    const redirectUri = "https://www.kashd.co/trustap-login";
    const responseType = "code";
    const scope = "openid p2p_tx:offline_create_join p2p_tx:offline_accept_deposit p2p_tx:offline_cancel p2p_tx:offline_confirm_handover";
    let state = userData ? userData.id : "undefined"

    const href = `${baseUrl}?client_id=${encodeURIComponent(clientId)}&client_secret=${encodeURIComponent(clientSecret)}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=${encodeURIComponent(responseType)}&scope=${encodeURIComponent(scope)}&state=${encodeURIComponent(state)}`;


    return (
        <Dialog
            open={props.open}
            onClose={() => props.setOpen(false)}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                    },
                },
            }}
        >
            <Grid
                container
                direction={"column"}
                justifyContent={"space-between"}
                sx={{
                    height: "30vh",
                    padding: "2rem"
                }}
            >

                <div className="register-details-text">To access funds you must first register with Trustap, our payment provider.</div>
                <Button
                    className="claim-button"
                    sx={{
                        ...props.buttonSx,
                        backgroundColor: "black"
                    }}
                    href={href}
                >
                    <div className="button-text">Click Here</div>
                </Button>
            </Grid>
        </Dialog >
    )
}