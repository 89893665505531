import React from "react";
import { Grid, MenuItem, Divider, Box, Typography, Stack } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function NotificationItem({ item }) {

    const navigate = useNavigate();

    const iconSx = { marginRight: "1rem", marginTop: "0.7rem" }

    const IconPicker = {
        'items': <AttachFileIcon sx={iconSx} />,
        'invitation': <InsertDriveFileIcon sx={iconSx} />,
        'revision': <FileCopyIcon sx={iconSx} />,
        'accept': <CheckCircleIcon sx={iconSx} />,
    }

    return (
        <MenuItem onClick={() => navigate(item.redirect)}>
            <Stack direction={"row"}>

                {IconPicker[item.type]}

                <Grid direction={"column"}>
                    <Box>
                        <Typography>{item.text}</Typography>
                        <Typography>{item.datetime}</Typography>
                    </Box>
                </Grid>
            </Stack>

            <Divider />
        </MenuItem>
    )
}