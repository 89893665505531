import React from 'react'
import Views from './Views'
import {
  BrowserRouter,
} from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';


function App() {


  const theme = createTheme({
    typography: {
      "fontFamily": `"Cabin", "Abel", "Arimo", "Helvetica", "Arial", sans-serif`,
      "fontSize": 14,
      "fontWeightLight": 300,
      "fontWeightRegular": 400,
      "fontWeightMedium": 500
    }
  });



  return (
    <div>
      <ThemeProvider theme={theme}>

        <BrowserRouter>
          <Views />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
