import React from 'react'
import EscrowOnly from './EscrowOnly/EscrowOnly';
import HeaderSidebar from '../HeaderSidebar';
import SelectionScreen from './TypeSelection/SelectionScreen';

export default function NewDealForm() {

    //Page is initiated as 1 until contract functionality is built in
    const [page, setPage] = React.useState(1)

    //THIS FUNCTION NAVIGATES BASED ON PAGE CLICKS
    //PASS IN THE PAGE AND THE SET PAGE -- FOR "BACK" FUNCTION REVERT TO PREVIOUS PAGE (NOT -1)
    function pageDisplay(page) {
        const screen = {
            0:
                <SelectionScreen setPage={setPage} />
            ,
            1:
                <EscrowOnly setPage={setPage} />
            ,
            2:
                "Contract and Escrow",
            3:
                "Contract Only"
        }
        return screen[page]
    }

    return (

        <>
            <HeaderSidebar />
            {pageDisplay(page)}
        </>
    )

}