import React from 'react'
import { useNavigate } from 'react-router-dom'
import NewDealForm from '../components/Initiate/NewDealForm';


export default function Initiate() {

    let navigate = useNavigate();

    return (
        <NewDealForm />
    )
}