import React from 'react'

import FormControl from '@mui/material/FormControl';
import { Dialog } from '@mui/material';
import { DialogTitle } from '@mui/material';
import { DialogContent } from '@mui/material';
import { TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Divider } from '@mui/material';

import './ItemDialog.css'

import { AddItems } from '../Notifications/NotificationFunctions'


export default function AddItemDialog(props) {

    const server = process.env.REACT_APP_SERVER_ADDRESS

    /*The ins-and-outs of this are a little murky, but it pairs up selected items from the dropdown (associated with the deal)
    and matches them up with items uploaded*/

    const [dragActive, setDragActive] = React.useState(false);
    const [items, setItems] = React.useState([])
    const [selectedItem, setSelectedItem] = React.useState(null)
    const [itemTypes, setItemTypes] = React.useState([])

    const [itemConfig, setItemConfig] = React.useState({})


    // ref
    const inputRef = React.useRef(null);

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped - IF/ELSE NOT REALLY NECESSARY
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const filename = e.dataTransfer.files[0].name
            if (items) {
                setItems([...items, ...e.dataTransfer.files])
                setItemConfig({
                    ...itemConfig, [filename]: {
                        file: e.dataTransfer.files[0],
                        quantity: 1,
                        item: 0
                    }
                })

            }
            else {
                //setItems([e.dataTransfer.files[0]])
                //setItemTypes([selectedItem])
                setItemConfig({
                    ...itemConfig, [filename]: {
                        file: e.dataTransfer.files[0],
                        quantity: 1,
                        item: 0
                    }
                })

            }
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            const filename = e.target.files[0].name

            if (items) {
                setItems([...items, ...e.target.files])
                setItemConfig({
                    ...itemConfig, [filename]: {
                        file: e.target.files[0],
                        quantity: 1,
                        item: null
                    }
                })
            }
            else {
                setItems([e.target.files])
                setItemConfig({
                    [filename]: {
                        file: e.target.files[0],
                        quantity: 1,
                        item: null
                    }
                })
            }
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };


    // sends data to server
    const handleSubmit = async (event) => {

        const filesArray = [];

        Object.keys(itemConfig).forEach((key) => {
            const fileObject = itemConfig[key];

            const formData = new FormData();
            formData.append('file', fileObject.file);
            formData.append('quantity', fileObject.quantity);
            formData.append('item', fileObject.item);

            filesArray.push(formData);
        });

        Promise.all(filesArray.map(formData => fetch(`${server}/item/upload`, {
            method: 'POST',
            body: formData,
        }))
        )
            .then((responses) => {
                const allResponsesSuccessful = responses.every(response => response.status === 200);

                if (allResponsesSuccessful) {
                    // if all items are uploaded successfully, close the dialog
                    props.setOpen(false);
                    AddItems(props.dealData.otherParty, `Items added to deal ${props.dealData.title}`, props.dealData.id, '/')
                    setItemConfig({})
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    const format = { width: "100%", mt: 1 }

    return (
        <>

            {
                props.dealData
                    ?
                    <Dialog
                        onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}
                        open={props.open}
                        onClose={() => (props.handleClose(), setSelectedItem(null), setItemConfig({}))}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title" className='item-header'>
                            <div className='item-header'>
                                Add Items
                            </div>
                        </DialogTitle>







                        <DialogContent
                            sx={{
                                minWidth: "17rem",
                            }}
                        >


                            <Table>
                                {Object.keys(itemConfig).map((filename) =>

                                    <TableRow>


                                        <TableCell
                                            sx={{
                                                width: 10
                                            }}
                                        >
                                            <p>{filename}</p>
                                        </TableCell>



                                        <TableCell sx={{ width: 80 }}>
                                            <TextField
                                                type='number'
                                                label="Contains Quantity"
                                                value={itemConfig[filename].quantity}
                                                onChange={(e) => {
                                                    setItemConfig(
                                                        prevConfig => ({
                                                            ...prevConfig,
                                                            [filename]: {
                                                                ...prevConfig[filename],
                                                                quantity: e.target.value
                                                            }
                                                        })
                                                    );

                                                }}
                                            />
                                        </TableCell>

                                        <TableCell >
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label">Item</InputLabel>

                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={itemConfig[filename].item}
                                                    label="Item"
                                                    sx={{ width: '10rem' }}
                                                    onChange={(e) => {
                                                        setItemConfig(
                                                            prevConfig => ({
                                                                ...prevConfig,
                                                                [filename]: {
                                                                    ...prevConfig[filename],
                                                                    item: e.target.value
                                                                }
                                                            })
                                                        );

                                                    }}
                                                >
                                                    {
                                                        props.items
                                                            ?
                                                            props.items.map((item) => (
                                                                <MenuItem key={item.id} value={item.id}>
                                                                    {item.description}
                                                                </MenuItem>
                                                            ))
                                                            :
                                                            null
                                                    }
                                                </Select>
                                            </FormControl>
                                        </TableCell>

                                    </TableRow>
                                )}
                            </Table>

                            <form id="form-file-upload" onSubmit={(e) => e.preventDefault()}>

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                        width: '100%',
                                        height: 200,
                                        border: 1,
                                        borderColor: 'grey.500',
                                        my: 1,
                                        background: dragActive ? 'grey' : 'white'
                                    }}>
                                    <div className='drag-text'>
                                        DRAG AND DROP FILE HERE
                                    </div>
                                    {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                                </Box>

                                <Button
                                    className="review-button"

                                    sx={{
                                        background: "black",
                                        width: "90%",
                                        height: "5vh",
                                        fontSize: "1rem",
                                        marginX: 2,
                                        marginY: 1
                                    }}
                                    onClick={onButtonClick}
                                >
                                    <Box
                                        className="button-text"
                                        sx={{
                                            color: "white"
                                        }}
                                    >
                                        Or Select File
                                    </Box>
                                    <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} hidden />
                                </Button>

                                <Divider sx={{ width: "100%", marginY: "3px", borderBottomWidth: 2, background: "dark grey" }} />

                                <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                                    <div>
                                        <Button
                                            className="review-button"
                                            sx={{
                                                background: "black",
                                                width: "90%",
                                                height: "5vh",
                                                fontSize: "1rem",
                                                marginX: 2,
                                                marginY: 1
                                            }} onClick={() => handleSubmit()} >
                                            <Box
                                                className="button-text"
                                                sx={{
                                                    color: "white"
                                                }}
                                            >
                                                Submit
                                            </Box>
                                        </Button>

                                    </div>
                                </label>

                            </form>
                        </DialogContent>
                    </Dialog>
                    :
                    <></>
            }
        </>
    );
}