import React from "react";
import OtherParty from "./TransactionDetails";
import { Grid, Button } from "@mui/material";
import { json } from "react-router-dom";

import TransactionDetails from "./TransactionDetails";
import ItemDetailsTable from "./ItemDetailsTable";
import PaymentDetails from "./PaymentDetails";
import TrustapDetails from "./TrustapDetails";

import SellerClaimButton from "./SellerClaimButton";
import BuyerClaimButton from "./BuyerClaimButton";

import BuyerCompleteButton from "./BuyerCompleteButton";
import SellerCompleteButton from "./SellerCompleteButton";

import CancelButton from "./CancelButton";

import EscrowStatus from "./EscrowStatus";
import ConfirmationStatus from "./ConfirmationStatus";

import AddItemDialog from "../Dashboard/AddItemDialog";
import AddFundsDialog from "../Dashboard/AddFundsDialog";

import ReviewButton from "./ReviewButton";
import ComplainButton from "./ComplainButton";

export default function ProfileContainer(props) {


    console.log("DEAL DATA", props.dealData)
    const [itemUploadOpen, setItemUploadOpen] = React.useState(false);
    const [fundsUploadOpen, setFundsUploadOpen] = React.useState(false);

    React.useEffect(() => {
        console.log(itemUploadOpen)
    }, [itemUploadOpen])


    const [itemsForUpload, setItemsForUpload] = React.useState(props.dealData.items);

    const handleFundsClose = () => {
        setFundsUploadOpen(false)
    }

    function handleItemClose() {
        setItemUploadOpen((oldOpen) => !oldOpen)
    }

    const dealData = props.dealData

    console.log(dealData)

    const userFriendlyText = {
        "escrowOnly": "Escrow Only",
        "contractOnly": "Contract Only",
        "contractEscrow": "Combined Contract & Escrow",
        "upfront": "Partial or Full Payment Upfront",
        "oncompletion": "Payment On Completion"
    }

    const transactionDetailsArray = [
        { 'key': "Title", "value": dealData.title },
        { 'key': "Transaction Features", "value": userFriendlyText[dealData.dealType] },
        { 'key': 'Payment Structure', "value": userFriendlyText[dealData.paymentType] },
        { 'key': 'Fee Structure', "value": dealData.buyerPaysFees ? "Buyer pays" : "Seller Pays" },
        { 'key': "Date Accepted", "value": dealData.dateAccepted },
        { 'key': "Due Date", "value": dealData.dueDate },
    ]

    const userDetailsArray = [
        { 'key': "Username", "value": props.username },
        { 'key': "Name", "value": props.name },
        { 'key': "Email", "value": props.email },
        { 'key': "Company Or Institution", "value": props.inst },
        { 'key': "Phone Number", "value": props.phone },
    ]



    const nonCanceledItems = <>
        {!dealData.accepted && (dealData.currentUser != dealData.iterationAuthor)
            ?
            <ReviewButton
                dealId={dealData.id}
            />
            :
            <></>
        }

        {
            !dealData.accepted
                ?
                <></>
                :
                <>
                    {props.seller ?
                        <SellerCompleteButton
                            seller={props.seller}
                            dealData={props.dealData}
                            setCompleteClicked={props.setCompleteClicked}
                        />
                        :
                        <BuyerCompleteButton
                            seller={props.seller}
                            dealData={props.dealData}
                            setCompleteClicked={props.setCompleteClicked}
                        />
                    }

                    {props.seller ?

                        < SellerClaimButton
                            completeClicked={props.completeClicked}
                            dealData={dealData}
                        />
                        :
                        <>
                            <BuyerClaimButton
                                completeClicked={props.completeClicked}
                                dealData={dealData}
                            />
                        </>
                    }

                    {
                        dealData.buyerComplete && dealData.sellerComplete
                            ?
                            <ComplainButton
                                dealData={dealData}
                            />
                            :
                            <CancelButton
                                dealData={dealData}
                            />
                    }

                </>

        }
    </>


    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
        >
            <Grid
                container
                spacing={0}
                direction="column"
                sx={{
                    width: "90%",
                }}
            >
                {
                    //<p>{JSON.stringify(props.dealData)}</p>
                }
                <TransactionDetails
                    title={"Transaction Details"}
                    detailsArray={transactionDetailsArray}
                />

                <TransactionDetails
                    title={"Other Party Details"}
                    detailsArray={userDetailsArray}
                />

                <ItemDetailsTable
                    seller={props.seller}
                    dealData={dealData}
                    setItemUploadOpen={setItemUploadOpen}
                    accepted={props.dealData.accepted}
                />


                <TrustapDetails
                    //"Payments" box
                    buyer={props.buyer}
                    dealData={dealData}
                    setFundsUploadOpen={setFundsUploadOpen}
                    accepted={props.dealData.accepted}
                />

                <AddItemDialog
                    dealData={dealData}
                    open={itemUploadOpen}
                    setOpen={setItemUploadOpen}
                    handleClose={handleItemClose}
                    items={itemsForUpload}
                    setItems={setItemsForUpload}
                />

                <AddFundsDialog
                    open={fundsUploadOpen}
                    handleClose={handleFundsClose}
                    dealTitle={props.dealData.title}
                    deal={props.dealData.id}
                    dealData={props.dealData}
                />

                <EscrowStatus
                    dealData={dealData}
                />

                <ConfirmationStatus
                    dealData={dealData}
                />

                {dealData.cancelled
                    ?
                    <></>
                    :
                    nonCanceledItems
                }


            </Grid>
        </Grid>

    )
}