import React from "react"
import { Stack } from "@mui/material"
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stepper from "./Stepper";
import { useNavigate } from "react-router-dom";
import './escrow.css'

export default function Review(props) {

    const server = process.env.REACT_APP_SERVER_ADDRESS;
    const navigate = useNavigate();

    const cancelDeal = () => {
        fetch(`${server}/deal/cancel`, {
            method: "POST",
            headers: {
                'Accept': 'Application/JSON',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("access_token")}`
            },
            body: JSON.stringify({
                id: data.id,
                cancellingUser: sessionStorage.getItem("user_id")
            })
        })
            .then(response => {
                if (response.status == 200) {
                    navigate('/')
                }
                else {
                    console.log('Error in submission')
                }
            })
    }


    const data = props.formData
    const keys = Object.keys(data)
    const [totalCost, setTotalCost] = React.useState();

    React.useEffect(() => {
        const items = props.formData.items
        let sum = 0

        for (let i = 0; i < items.length; i++) {
            sum += (items[i].unitCost * items[i].quantity)
        }

        setTotalCost(sum)
    }, [props.formData])


    const prevPage = () => {
        props.setPage(0)
    }


    function feeAdded(amount) {
        return (amount * 1.03) + 0.8
    }

    function feeTaken(amount) {
        return (amount - 0.8) / 1.03

    }


    function calculateBuyer() {
        if (data.paymentType != "oncompletion") {
            const upfront = feeAdded(data.upfrontPayment)
            const escrow = data.escrowPayment > 0 ? feeAdded(data.escrowPayment) : 0
            return <span className="highlight-text">£{(upfront + escrow).toFixed(2)}</span>
        }
        else {
            return <span className="highlight-text">£{feeAdded(data.escrowPayment).toFixed(2)}</span>
        }
    }

    function calculateSeller() {
        if (data.paymentType != "oncompletion") {
            const upfront = feeTaken(data.upfrontPayment)
            const escrow = data.escrowPayment > 0 ? feeTaken(data.escrowPayment) : 0
            return <span className="highlight-text">£{(upfront + escrow).toFixed(2)}</span>
        }
        else {
            return <span className="highlight-text">£{feeTaken(data.escrowPayment).toFixed(2)}</span>
        }
    }

    const intersectionKeys = (obj1, obj2) => {
        return Object.keys(obj1).filter(key => obj2.hasOwnProperty(key));
    }

    const compareChanges = (obj1, obj2) => {
        const matchingKeys = intersectionKeys(obj1, obj2)

        const ordered = (unordered) => {
            return Object.keys(unordered).sort().reduce(
                (obj, key) => {
                    obj[key] = unordered[key];
                    return obj;
                },
                {}
            )
        }

        const filt1 = Object.fromEntries(matchingKeys.map(col => [col, obj1[col]]));
        const filt2 = Object.fromEntries(matchingKeys.map(col => [col, obj2[col]]));


        const sorted1 = ordered(filt1)
        const sorted2 = ordered(filt2)


        const dateString = sorted1.dueDate

        // Create a new Date object with the given date string
        const date = new Date(dateString);

        // Get the month, day, and year components from the Date object
        const month = date.getMonth() + 1; // Months are zero-based, so add 1
        const day = date.getDate();
        const year = date.getFullYear();

        // Pad single-digit values with leading zeros if needed
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;

        // Construct the MM/DD/YYYY format string
        const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
        sorted1.dueDate = formattedDate


        console.log(sorted1)
        console.log(sorted2)

        if (JSON.stringify(sorted1) === JSON.stringify(sorted2)) {
            return true
        }
        else {
            return false
        }
        /*
        for (let i = 0; i < matchingKeys.length; i++) {
            const key = matchingKeys[i]
            if (obj1.key != obj2.key) {
                return false
            }
        }
        */
    }

    let revealUsername;
    let revealEmail;
    let revealName;
    let revealPhone;

    if (data.authorRole == "seller") {
        revealUsername = data.sellerUsername
        revealEmail = data.sellerEmail
        revealName = data.sellerName
        revealPhone = data.sellerPhone
    }
    else {
        revealUsername = data.buyerUsername
        revealEmail = data.buyerEmail
        revealName = data.buyerName
        revealPhone = data.buyerPhone
    }


    function transactionValue() {
        if (data.paymentType != "oncompletion") {
            return parseFloat(data.upfrontPayment) + parseFloat(data.escrowPayment)
        }
        else {
            return "yeah"
            return data.escrowPayment
        }
    }



    return (
        <>

            <Stepper direction={'backwards'} active={true} clickFunction={() => props.setEscrowStep(1)} />

            <Grid
                container
                direction={"row"}
                justifyContent={"center"}
                alignContent={"center"}
                sx={{
                    height: "80vh",
                    width: "90vw"
                }
                }
            >

                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    sx={{ width: "100%" }}
                >

                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        justifyContent={"center"}
                        alignContent={"flex-start"}
                        className="review-container"
                        sx={{ marginLeft: "40px" }}
                    >
                        <div className="review-line">You{data.inst ? <span>, representing <span className="highlight-text">{data.inst}</span>,</span> : undefined} are {data.authorRole == "buyer" ? <span><span className="highlight-text">buying</span> from</span> : <span><span className="highlight-text">selling</span> to</span>} <span className="highlight-text">{data.otherParty}</span></div>
                        <div className="review-line">This transaction is worth <span className="highlight-text">£{totalCost}</span> and consists of:</div>
                        {
                            data.items.map(i =>
                                <div className="review-item">{i.quantity}x <span className="highlight-text">{i.description}</span> worth <span className="highlight-text">£{i.quantity * i.unitCost}</span> total</div>
                            )
                        }
                        <div className="review-line" style={data.paymentType != "oncompletion" ? { 'display': 'block' } : { 'display': 'none' }}><span className="highlight-text">£{data.upfrontPayment}</span> is to be paid upfront</div>

                        <div className="review-line">Fees are covered by the <span className="highlight-text">{data.buyerPaysFees == "true" ? "buyer" : "seller"}</span></div>

                        <div className="review-item">Buyer will pay <span className="highlight-text">{data.buyerPaysFees == "true" ? calculateBuyer() : <span className="highlight-text">£{(parseFloat(data.upfrontPayment) + parseFloat(data.escrowPayment))}</span>} total</span></div>

                        <div className="review-item">Seller will receive <span className="highlight-text">{data.buyerPaysFees == "true" ? <span className="highlight-text">£{transactionValue()}</span> : calculateSeller()} total</span></div>


                        <div className="review-line">The final due date for completion is <span className="highlight-text">{data.dueDate}</span></div>
                        <div className="review-line">{data.otherParty} will be able to see:</div>
                        <div className="review-item" style={revealUsername ? { 'display': 'block' } : { 'display': 'none' }}>Your <span className="highlight-text">Kashd username</span></div>
                        <div className="review-item" style={revealEmail ? { 'display': 'block' } : { 'display': 'none' }}>Your <span className="highlight-text">email address</span></div>
                        <div className="review-item" style={revealName ? { 'display': 'block' } : { 'display': 'none' }}>Your <span className="highlight-text">full name</span></div>
                        <div className="review-item" style={revealPhone ? { 'display': 'block' } : { 'display': 'none' }}>Your <span className="highlight-text">phone number</span></div>
                    </Grid>


                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        justifyContent={"center"}
                        alignContent={"center"}
                        className="review-container"
                    >

                        {
                            compareChanges(props.dealData, props.formData)
                                ?
                                <Button
                                    onClick={() => props.acceptDeal()}
                                    className="review-button"
                                    sx={{
                                        background: "black",
                                        color: "white",
                                        width: "30vw",
                                        height: "10vh",
                                        fontSize: "1.5rem",
                                        margin: 2
                                    }}
                                >
                                    <div className="button-text">Accept In Current Form</div>
                                </Button>
                                :
                                <Button
                                    onClick={() => props.handleSubmit()}
                                    className="review-button"
                                    sx={{
                                        background: "black",
                                        color: "white",
                                        width: "30vw",
                                        height: "10vh",
                                        fontSize: "1.5rem",
                                        margin: 2
                                    }}
                                >
                                    <div className="button-text">Submit Revision</div>
                                </Button>
                        }

                        <Button
                            onClick={() => cancelDeal()}
                            className="review-button"
                            sx={{

                                background: "black",
                                color: "white",
                                width: "30vw",
                                height: "10vh",
                                fontSize: "1.5rem",
                                margin: 2
                            }}
                        >
                            <div className="button-text">Reject & Cancel</div>
                        </Button>


                        <Button
                            onClick={() => props.setEscrowStep(1)}
                            className="review-button"
                            sx={{

                                background: "black",
                                color: "white",
                                width: "30vw",
                                height: "10vh",
                                fontSize: "1.5rem",
                                margin: 2
                            }}
                        >
                            <div className="button-text">Back</div>
                        </Button>

                    </Grid>
                </Stack>


            </Grid >
        </>

    )

}
