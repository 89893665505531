import React from "react";

export function AddItems(user, text, deal, redirect) {
    const server = process.env.REACT_APP_SERVER_ADDRESS

    fetch(`${server}/notification/addnotification`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            user: user,
            text: text,
            deal: deal,
            redirect: redirect,
            type: "items"
        })
    })
        .then(response => response.json())
        .then(json => console.log(json))
}

export function AddNotification(user, text, deal, redirect, type) {
    const server = process.env.REACT_APP_SERVER_ADDRESS

    fetch(`${server}/notification/addnotification`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            user: user,
            text: text,
            deal: deal,
            redirect: redirect,
            type: type
        })
    })
        .then(response => response.json())
        .then(json => console.log(json))
}

export async function GetNotifications(user) {
    const server = process.env.REACT_APP_SERVER_ADDRESS

    return fetch(`${server}/notification/getnotifications`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            user: user,
        })
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(json => {
            const data = json.data;
            return data;
        })
        .catch(error => {
            // Handle errors here (e.g., log them or throw a custom error)
            throw error;
        });
}

export async function ReadNotifications(notifs) {
    const server = process.env.REACT_APP_SERVER_ADDRESS

    return fetch(`${server}/notification/readnotifications`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            notifs: notifs,
        })
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return console.log(response.json());
        })
}