import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { Popover, Grid } from '@mui/material';
import Badge from '@mui/material/Badge';

import MenuItem from '@mui/material/MenuItem';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { ReadNotifications } from '../Notifications/NotificationFunctions';

import NotificationItem from './NotificationItem';

import LogoutElement from '../AvatarMenu/Logout';

import { useNavigate } from 'react-router-dom';


export default function Header({ setSidebarOpened, notifications }) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorNotif, setAnchorNotif] = React.useState(null);

    const [notificationsOpened, setNotficiationsOpened] = React.useState(false);

    //const [unreadNotifs, setUnreadNotifs] = 

    const navigate = useNavigate();

    function handleClose() {
        setAnchorEl(null)
        setAnchorNotif(null)
    }

    const openTools = Boolean(anchorEl);
    const openNotifs = Boolean(anchorNotif);


    const handleOpenUserMenu = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const handleOpenNotifications = (e) => {
        setAnchorNotif(e.currentTarget)
        if (notifications) {
            const readNotifs = notifications.map((i) => i.id)
            console.log(readNotifs)
            setNotficiationsOpened(true)
            ReadNotifications(readNotifs)

        }
    }

    const getNotificationCount = () => {

        if (notifications && notifications.length > 0) {
            const unread = notifications.filter((i) => !i.read).length

            if (notificationsOpened) {
                return 0
            }
            else if (unread > 5) {
                return '5+'
            }
            else {
                return unread
            }
        }
        else {
            return 0
        }



    }

    const userName = sessionStorage.getItem("user_name")

    return (
        <AppBar
            position="sticky"
            zIndex="9999"
            sx={{
                bgcolor: '#000000',
                top: 0
            }}
        >
            <Container maxWidth="100vw">
                <Toolbar disableGutters
                    sx={{
                        justifyContent: 'space-between'
                    }}>


                    <MenuIcon
                        sx={{
                            bgcolor: 'none',
                            borderRadius: "50%",
                            width: 32,
                            height: 32,
                            color: '#ffffff',
                            cursor: 'pointer'
                        }}

                        onClick={() => setSidebarOpened(oldSidebar => !oldSidebar)}
                    />


                    <Tooltip title="Notifications">
                        <Badge badgeContent={getNotificationCount()}>
                            <IconButton onClick={handleOpenNotifications}>

                                <NotificationsIcon
                                    sx={{
                                        bgcolor: 'none',
                                        borderRadius: "50%",
                                        width: 32,
                                        height: 32,
                                        color: '#ffffff',
                                        cursor: 'pointer'
                                    }} />
                            </IconButton>

                        </Badge>
                    </Tooltip>

                    <Popover
                        anchorEl={anchorNotif}
                        id="account-menu"
                        open={openNotifs}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    >

                        {notifications && notifications.length > 0
                            ?
                            (notifications.slice(0, 5)).map((i) =>
                                <NotificationItem item={i} />
                            )

                            :
                            <></>
                        }
                        <MenuItem onClick={() => navigate('/notifications')} >
                            <Grid direction={"column"}>
                                <Box>
                                    <Typography>All Notifications</Typography>
                                </Box>
                            </Grid>
                            <Divider />
                        </MenuItem>
                    </Popover>



                    <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu}>
                            <Avatar alt={userName} src="/static/images/avatar/2.jpg" />
                        </IconButton>
                    </Tooltip>

                    <Popover
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={openTools}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    >

                        <MenuItem onClick={() => navigate('/profile')}>
                            <Avatar /> My account
                        </MenuItem>

                        <Divider />

                        <LogoutElement />

                    </Popover>




                </Toolbar>
            </Container>
        </AppBar >
    );
}

/*
  <MenuItem onClick={handleClose}>
    <ListItemIcon>
      <Settings fontSize="small" />
    </ListItemIcon>
    Settings
  </MenuItem>
*/