import React from "react";
import HeaderSidebar from "../../HeaderSidebar";
import { Grid } from "@mui/material";

import UserConfig from "./UserConfig";
import ItemConfig from "./ItemConfig";
import MoneyConfig from "./MoneyConfig";
import Review from "./Review";
import { useNavigate } from "react-router-dom";
import moment from 'moment'
import { AddNotification } from "../../Notifications/NotificationFunctions";


export default function EscrowOnly(props) {

    const server = process.env.REACT_APP_SERVER_ADDRESS

    const navigate = useNavigate();

    const [escrowStep, setEscrowStep] = React.useState(0)
    const [changed, setChanged] = React.useState();


    const date = new Date()

    const dealData = props.dealData.current
    const referenceData = JSON.parse(JSON.stringify(dealData));

    const buyerData = props.dealData.buyer
    const sellerData = props.dealData.seller

    const currentUser = sessionStorage.getItem('user_id')

    const dateToString = (date) => {
        return date ? moment(date).format('DD/MM/YYYY') : ''
    };


    const [formData, setFormData] = React.useState({
        title: dealData.title,
        id: dealData.id,
        iteration: dealData.iteration,
        author: currentUser,
        dateCreated: new Date().toJSON(),
        dealType: dealData.dealType,
        otherParty: currentUser == buyerData.id ? sellerData.email : buyerData.email,
        otherPartyInst: currentUser == buyerData.id ? dealData.sellerInst : dealData.buyerInst,
        inst: currentUser == buyerData.id ? dealData.buyerInst : dealData.sellerInst,
        paymentType: dealData.paymentType,
        upfrontPayment: dealData.upfrontPayment,
        authorRole: currentUser == dealData.seller ? "seller" : "buyer",
        dueDate: dateToString(dealData.dueDate),
        items: dealData.items,
        sellerEmail: dealData.sellerEmail,
        sellerUsername: dealData.sellerUsername,
        sellerPhone: dealData.sellerPhone,
        sellerName: dealData.sellerName,
        buyerEmail: dealData.buyerEmail,
        buyerUsername: dealData.buyerUsername,
        buyerPhone: dealData.buyerPhone,
        buyerName: dealData.buyerName,
        buyerPaysFees: dealData.buyerPaysFees,
        depositIncluded: dealData.depositIncluded,
        escrowPayment: dealData.escrowPayment
    })

    const handleSubmit = () => {

        fetch(`${server}/deal/escrowonlyupdate`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "title": formData.title,
                "id": formData.id,
                "iteration": formData.iteration,
                "author": formData.author,
                "dateCreated": formData.dateCreated,
                "dealType": formData.dealType,
                "otherParty": formData.otherParty,
                "otherPartyInst": formData.otherPartyInst,
                "inst": formData.inst,
                "paymentType": formData.paymentType,
                "authorRole": formData.authorRole,
                "items": formData.items,
                "upfrontPayment": formData.upfrontPayment,
                "dueDate": formData.dueDate,
                "sellerEmail": formData.sellerEmail,
                "sellerUsername": true,
                "sellerPhone": formData.sellerPhone,
                "sellerName": formData.sellerName,
                "buyerEmail": formData.buyerEmail,
                "buyerUsername": true,
                "buyerPhone": formData.buyerPhone,
                "buyerName": formData.buyerName,
                "buyerPaysFees": formData.buyerPaysFees,
                "depositIncluded": formData.depositIncluded,
                "escrowPayment": formData.escrowPayment
            })
        })
            .then(resp => resp.json())
            .then(data =>
                AddNotification(data.data.username, `New revision of ${formData.title} submitted`, data.data.deal, `/review/${data.data.deal}`, 'revision')
            )
            .then(navigate('/'))
    }

    const acceptDeal = () => {
        console.log({ "acceptingUser": formData.currentUser })

        fetch(`${server}/deal/escrowonlyaccept`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "id": formData.id,
                "iteration": formData.iteration,
                "acceptingUser": currentUser
            })
        })
            .then(resp => resp.json())
            .then(data => AddNotification(data.data.username, `${formData.title} accepted`, data.data.deal, '/', 'accept'))
            .then(navigate('/'))
    }


    //Set Components for each page change with the step selcted in the stepper displaying it
    //Send form from THIS page rather than passing props back through again

    function pageDisplay(page) {
        const screen = {
            0:
                <UserConfig changed={changed} setChanged={setChanged} setPage={props.setPage} setEscrowStep={setEscrowStep} buyer={buyerData} seller={sellerData} formData={formData} setFormData={setFormData} differences={props.differences} />
            ,
            1:
                <ItemConfig setChanged={setChanged} reference={referenceData} formData={formData} setEscrowStep={setEscrowStep} setFormData={setFormData} differences={props.differences} />
            ,
            2:
                <MoneyConfig
                    formData={formData}
                    setFormData={setFormData}
                    setEscrowStep={setEscrowStep}
                    differences={props.differences}
                />
            ,
            3:
                <Review changed={changed} formData={formData} dealData={referenceData} handleSubmit={handleSubmit} setEscrowStep={setEscrowStep} acceptDeal={acceptDeal} />
        }
        return screen[page]
    }

    return (
        dealData ?
            <>
                <HeaderSidebar />

                <Grid
                    container
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}

                >

                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            width: "85vw",
                            zIndex: 1,
                        }}
                    >
                        {pageDisplay(escrowStep)}
                    </Grid>
                </Grid>
            </>
            :
            <></>
    )
}