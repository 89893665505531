import React from 'react'
import Button from '@mui/material/Button'
import { useParams, useNavigate } from 'react-router-dom';
import './button.css'
/*

- Deal name (ADD TO FORM)
- Other party
- Selling/buying
- Days until due
- Value
- Provided upfront / upfront total (e.g. £80/£100)
- Status (invitation, awaiting approval for contract or deal, active)
*/


export default function AllColumns(reviewDeal, setItemUploadOpen, setFundsUploadOpen, setItemsForUpload, setDealTitle, setDeal, setDealData) {

    const navigate = useNavigate();


    const renderActionsButton = (params) => {

        const ifBuyer = () => {
            setDealTitle(params.row.title);
            setDeal(params.row.id);
            setDealData(params.row);
            setFundsUploadOpen(true);
        }

        const ifSeller = () => {
            setItemsForUpload(params.row.items);
            setDealData(params.row);
            setItemUploadOpen(true);

        }

        const style = {
            "font-family": 'Bebas Neue',
            "color": "black",
            '&:hover': {
                "color": "white",
            }
        }

        return (
            params.row.status == 'Active'
                ?

                <Button
                    className='dashboard-button'
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={(event) => {
                        event.stopPropagation(); // Prevent the click event from propagating

                        console.log(params.row)
                        setDealTitle(params.row.title)
                        params.row.role == 'Buyer' ?
                            ifBuyer()
                            :
                            ifSeller()
                        //setItemUploadOpen(true); setItemsForUpload(params.row.title);
                    }}
                >
                    {
                        params.row.role == 'Buyer' ?
                            <div className='dashboard-button'>Add funds</div>
                            :
                            <div className='dashboard-button'>Add Items</div>
                    }
                </Button>
                :
                params.row.status == 'Response required'
                    ?
                    <Button
                        className='dashboard-button'
                        variant="text"
                        color="primary"
                        size="small"
                        onClick={(event) => {
                            event.stopPropagation(); // Prevent the click event from propagating

                            console.log(params.row)
                            reviewDeal(params.row.id)
                        }}
                    >
                        <div className='dashboard-button'>Review</div>
                    </Button>
                    :
                    <></>

        )
    }

    const renderDetailsButton = (params) => {


        const style = {
            "font-family": 'Bebas Neue',
            "color": "black",
            '&:hover': {
                "color": "white",
            }
        }

        return (
            <Button
                className='dashboard-button'
                variant="text"
                color="primary"
                size="small"
                onClick={() => navigate(`/transactionprofile/${params.row.id}`)}
            >
                <div className='dashboard-button'>Details</div>
            </Button>

        )
    }


    const renderCancelButton = (params) => {
        return (
            <Button
                className='dashboard-button'
                variant="text"
                color="primary"
                size="small"
                onClick={(event) => {
                    event.stopPropagation(); // Prevent the click event from propagating

                    console.log(params.row)
                    reviewDeal(params.row.id)
                }}
            >
                <div className='dashboard-button'>Cancel2</div>
            </Button>
        )
    }


    const redirectingCell = (params, field) => (
        <div onClick={() => navigate(`/transactionprofile/${params.row.id}`)}> {params.row[field]}</div >
    )


    const formatDate = (dateString) => {
        const date = new Date(dateString);

        // Extract the day, month, and year from the Date object
        const day = ("0" + date.getDate()).slice(-2); // Add leading zero if needed
        const month = ("0" + (date.getMonth() + 1)).slice(-2); // Add leading zero if needed, month is 0-indexed
        const year = date.getFullYear();

        // Concatenate in DD/MM/YYYY format
        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate
    }


    const AllCols = [

        {
            field: 'title',
            headerName: 'Title',
            width: 200,
            renderCell: (params) => redirectingCell(params, "title")
        },
        {
            field: 'role',
            headerName: 'Your Role',
            width: 100,
            renderCell: (params) => redirectingCell(params, "role")
        },
        {
            field: 'otherParty',
            headerName: 'Other Party',
            width: 150,
            renderCell: (params) => redirectingCell(params, "otherParty")

        },
        {
            field: 'status',
            headerName: 'Status',
            width: 50,
            renderCell: (params) => redirectingCell(params, "status")

        },
        /*
        {
            field: 'upfront',
            headerName: 'Upfront Payment',
            width: 80,
            renderCell: (params) => (
                params.row.upfront == 0
                    ?
                    <span>No upfront payment</span>
                    :
                    params.row.upfront
            ),
        },
        {
            field: 'escrow',
            headerName: 'Escrow Amount',
            width: 80,
            renderCell: (params) => (
                params.row.escrow == 0
                    ?
                    <span>No payment</span>
                    :
                    params.row.escrow
            ),
        },
        */
        {
            field: 'totalpaid',
            headerName: 'Total Paid',
            width: 80,
            renderCell: (params) => (
                params.row.totalPaid == 0
                    ?
                    <span>0</span>
                    :
                    params.row.totalPaid
            ),
        },
        {
            field: 'creation',
            headerName: 'Date Created',
            width: 100,
            renderCell: (params) => formatDate(params.row.creation)
        },
        {
            field: 'dueDate',
            headerName: 'Due Date',
            width: 100,
            renderCell: (params) => formatDate(params.row.dueDate)

        },

        {
            field: 'daysUntil',
            headerName: 'Days Left',
            width: 100
        },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: renderActionsButton
        },
        {
            field: 'details',
            headerName: 'Details',
            renderCell: renderDetailsButton
        },


    ]

    return (
        AllCols
    )
}