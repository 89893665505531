import React, { useEffect } from "react"
import { Box, Portal, Stack } from "@mui/material"
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";
import './escrow.css'
import Stepper from "../Stepper";
import { useMediaQuery } from "react-responsive";

import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";



export default function Review(props) {

    const isMobile = useMediaQuery({
        query: '(max-device-width: 900px)',
    });


    const data = props.formData
    const keys = Object.keys(data)
    const [totalCost, setTotalCost] = React.useState();

    console.log(data)

    React.useEffect(() => {
        const items = props.formData.items
        let sum = 0

        for (let i = 0; i < items.length; i++) {
            sum += (items[i].unitCost * items[i].quantity)
        }

        setTotalCost(sum)
    }, [props.formData])

    const prevPage = () => {
        props.setEscrowStep(currentStep => currentStep - 1)
    }



    function transactionValue() {
        if (data.paymentType != "oncompletion") {
            return parseFloat(data.upfrontPayment) + parseFloat(data.escrowPayment)
        }
        else {
            return data.escrowPayment
        }
    }

    function feeAdded(amount) {
        return (amount * 1.03) + 0.8
    }

    function feeTaken(amount) {
        return (amount - 0.8) / 1.03

    }

    function calculateBuyer() {
        if (data.paymentType != "oncompletion") {
            const upfront = feeAdded(data.upfrontPayment)
            const escrow = data.escrowPayment > 0 ? feeAdded(data.escrowPayment) : 0
            return <span className="highlight-text">£{(upfront + escrow).toFixed(2)}</span>
        }
        else {
            return <span className="highlight-text">£{feeAdded(data.escrowPayment).toFixed(2)}</span>
        }
    }

    function calculateSeller() {
        if (data.paymentType != "oncompletion") {
            const upfront = feeTaken(data.upfrontPayment)
            const escrow = data.escrowPayment > 0 ? feeTaken(data.escrowPayment) : 0
            return <span className="highlight-text">£{(upfront + escrow).toFixed(2)}</span>
        }
        else {
            return <span className="highlight-text">£{feeTaken(data.escrowPayment).toFixed(2)}</span>
        }
    }

    /*
    function calculateSeller(upfront, total) {
        const escrow = total
        return <span className="highlight-text">£{(((upfront - 0.8) / 1.03) + ((escrow - 0.8) / 1.03)).toFixed(2)}</span>
    }
    */






    return (
        <>
            <Stepper direction={'backwards'} active={true} clickFunction={prevPage} />

            <Grid
                container
                direction={"row"}
                justifyContent={"center"}
                alignContent={"center"}
                sx={{
                    width: '90vw',
                    height: "auto",
                    marginTop: "30px",

                }}
            >

                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    sx={{
                        marginTop: isMobile ? "20%" : "auto",
                        width: "100%"
                    }}
                >
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        justifyContent={"center"}
                        alignContent={"flex-start"}
                        className="review-container"
                        sx={{ marginLeft: "40px" }}
                    >
                        <div className="review-line">You{data.inst ? <span>, representing <span className="highlight-text">{data.inst}</span>,</span> : undefined} are {data.authorRole == "buyer" ? <span><span className="highlight-text">buying</span> from</span> : <span><span className="highlight-text">selling</span> to</span>} <span className="highlight-text">{data.otherParty}</span></div>
                        <div className="review-line">This transaction is worth <span className="highlight-text">£{transactionValue()}</span> and consists of:</div>

                        <div className="review-item" style={(data.paymentType != "oncompletion") && (data.depositIncluded == false) ? { 'display': 'block' } : { 'display': 'none' }}><span className="highlight-text">A £{data.upfrontPayment}</span> upfront payment (before fees)</div>

                        {
                            data.items.map(i =>
                                <div className="review-item">{i.quantity}x <span className="highlight-text">{i.description}</span> worth <span className="highlight-text">£{i.quantity * i.unitCost}</span> total</div>
                            )
                        }


                        <div className="review-line">Fees are covered by the <span className="highlight-text">{data.buyerPaysFees == true ? "buyer" : "seller"}</span></div>


                        <div className="review-item">Buyer will pay <span className="highlight-text">{data.buyerPaysFees == true ? calculateBuyer() : <span className="highlight-text">£{(parseFloat(data.upfrontPayment) + parseFloat(data.escrowPayment))}</span>} total</span></div>

                        <div className="review-item" style={(data.paymentType != "oncompletion") && (data.depositIncluded == true) ? { 'display': 'block' } : { 'display': 'none' }}><span className="highlight-text">£{data.upfrontPayment}</span> (before fees) is to be paid upfront</div>

                        <div className="review-item">Seller will receive <span className="highlight-text">{data.buyerPaysFees == true ? <span className="highlight-text">£{transactionValue()}</span> : calculateSeller()} total</span></div>

                        <div className="review-line">The final due date for completion is <span className="highlight-text">{data.dueDate}</span></div>
                        <div className="review-line">{data.otherParty} will be able to see:</div>
                        <div className="review-item" style={data.revealUsername ? { 'display': 'block' } : { 'display': 'none' }}>Your <span className="highlight-text">Kashd username</span></div>
                        <div className="review-item" style={data.revealEmail ? { 'display': 'block' } : { 'display': 'none' }}>Your <span className="highlight-text">email address</span></div>
                        <div className="review-item" style={data.revealName ? { 'display': 'block' } : { 'display': 'none' }}>Your <span className="highlight-text">full name</span></div>
                        <div className="review-item" style={data.revealPhone ? { 'display': 'block' } : { 'display': 'none' }}>Your <span className="highlight-text">phone number</span></div>
                    </Grid>

                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        justifyContent={"center"}
                        alignContent={"center"}
                        className="review-container"
                    >

                        <Button
                            onClick={() => props.handleSubmit()}
                            className="review-button"
                            sx={{
                                background: "black",
                                color: "white",
                                width: "30vw",
                                height: "10vh",
                                fontSize: "1.5rem",
                                margin: 2
                            }}
                        >
                            <div className="button-text">Submit</div>
                        </Button>


                        <Button
                            onClick={() => props.setEscrowStep(1)}
                            className="review-button"
                            sx={{

                                background: "black",
                                color: "white",
                                width: "30vw",
                                height: "10vh",
                                fontSize: "1.5rem",
                                margin: 2
                            }}
                        >
                            <div className="button-text">Back</div>
                        </Button>

                    </Grid>
                </Stack>


            </Grid >
        </>

    )

}
