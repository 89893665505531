import React from 'react'

import { Dialog, Grid, Stack } from '@mui/material';
import { DialogTitle } from '@mui/material';
import { DialogContent } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import PaymentButton from '../Trustap/PaymentButton';

//import TrustshareUI from '../Trustshare/TrustshareUI';

import './fundsDialog.css'

export default function AddFundsDialog(props) {

    const [amount, setAmount] = React.useState();

    const [confirm, setConfirm] = React.useState(false);

    const format = { width: "100%", mt: 1 }

    const sellerPayingFees = (value) => {
        return ((value - 0.8) / 1.03)
    }

    return (
        props.dealData

            ?

            <Dialog
                open={props.open}
                onClose={() => { props.handleClose(); setConfirm(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                {JSON.stringify(props.dealData)}

                <DialogTitle
                    id="alert-dialog-title"
                >
                    <div className="title">
                        Add Funds
                    </div>
                </DialogTitle>

                <DialogContent
                    sx={{
                        minWidth: "17rem",
                    }}
                >
                    <Stack direction={"column"}>

                        <Stack
                            sx={{
                                direction: "column"
                            }}
                        >

                            <PaymentButton
                                amount={props.dealData.buyerPaysFees ? props.dealData.upfrontPayment : sellerPayingFees(props.dealData.upfrontPayment)}
                                dealTitle={`Deposit - ${props.dealTitle}`}
                                deal={props.deal}
                                dealData={props.dealData}
                                title={props.dealData.upfrontPaid ? "Deposit Paid" : "Pay Deposit"}
                                type={"deposit"}
                                disabled={props.dealData.paymentType == "oncompletion" ? true : false}
                                paid={props.dealData.upfrontPaid}
                            />

                            <PaymentButton
                                amount={props.dealData.buyerPaysFees ? props.dealData.escrowPayment : sellerPayingFees(props.dealData.escrowPayment)}
                                dealTitle={`Escrow - ${props.dealTitle}`}
                                deal={props.deal}
                                dealData={props.dealData}
                                title={props.dealData.escrowPaid ? "Escrow Paid" : "Pay Escrow Amount"}
                                type={"escrow"}
                                paid={props.dealData.escrowPaid}
                            />

                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
            :
            <></>
    );
}