import React from "react";
import HeaderSidebar from "../components/HeaderSidebar";
import { Grid, Stack, Box, Divider, Button } from "@mui/material";
import ItemStack from "../components/Profile/ItemStack";
import DialogParent from "../components/Profile/DialogParent";
import './css/Profile.css'

import { useMediaQuery } from "react-responsive";


export function Profile() {

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogType, setDialogType] = React.useState();
    const [dialogTitle, setDialogTitle] = React.useState();
    const [content, setContent] = React.useState();

    const isMobile = useMediaQuery({
        query: '(max-device-width: 900px)',
    });

    const userId = sessionStorage.getItem('user_id')
    const server = process.env.REACT_APP_SERVER_ADDRESS

    const [userData, setUserData] = React.useState(undefined)

    React.useEffect(() => {
        fetch(`${server}/user/userdetails`, {
            method: 'POST',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body:
                JSON.stringify({
                    id: userId
                })
        })
            .then(resp => resp.json())
            .then(data => setUserData(data.data))
            .then(console.log(userData))
    }, [])

    React.useEffect(() => {
        console.log('userdata', userData)
    }, [userData])


    const baseUrl = "https://sso.trustap.com/auth/realms/trustap-stage/protocol/openid-connect/auth";
    const clientId = "355dfef2-3d30-486e-a371-df556e63a3b9";
    const clientSecret = "d17eef18-6540-4ffe-8030-064f474aa487";
    const redirectUri = "https://www.kashd.co/trustap-login";
    const responseType = "code";
    const scope = "openid p2p_tx:offline_create_join p2p_tx:offline_accept_deposit p2p_tx:offline_cancel p2p_tx:offline_confirm_handover";
    let state = userData ? userData.id : "undefined"

    const href = `${baseUrl}?client_id=${encodeURIComponent(clientId)}&client_secret=${encodeURIComponent(clientSecret)}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=${encodeURIComponent(responseType)}&scope=${encodeURIComponent(scope)}&state=${encodeURIComponent(state)}`;



    return (
        <>
            <HeaderSidebar />
            <Grid
                container
                direction={"row"}
                justifyContent="center"
                alignItems="center"
                sx={{
                    marginLeft: isMobile ? 3 : "auto",
                    marginBottom: 5
                }}
            >
                <Stack direction="row"
                    sx={{
                        width: "95vw",
                        marginTop: 3,
                        marginBottom: 2
                    }}
                >
                    <div className="header-text">Profile Details</div>
                </Stack>

                {userData
                    ?
                    <Box
                        sx={{
                            width: "95%",
                            height: "100%",
                            fontSize: "1rem",
                        }}
                    >

                        <ItemStack setDialogType={setDialogType} setDialogOpen={setDialogOpen} setDialogTitle={setDialogTitle} setContent={setContent} title={"Username"} type={'email'} content={userData.username} editable={false} />

                        <ItemStack setDialogType={setDialogType} setDialogOpen={setDialogOpen} setDialogTitle={setDialogTitle} setContent={setContent} title={"Email Address"} type={'email'} content={userData.email} editable={false} />

                        <ItemStack setDialogType={setDialogType} setDialogOpen={setDialogOpen} setDialogTitle={setDialogTitle} setContent={setContent} title={"Name"} type={'name'} content={`${userData.firstname} ${userData.lastname}`} editable={true} firstname={userData.firstname} lastname={userData.lastname} />

                        <ItemStack setDialogType={setDialogType} setDialogOpen={setDialogOpen} setDialogTitle={setDialogTitle} setContent={setContent} title={"Phone Number"} type={'phone'} content={userData.phone} editable={true} />

                        <ItemStack setDialogType={setDialogType} setDialogOpen={setDialogOpen} setDialogTitle={setDialogTitle} setContent={setContent} title={"Password"} type={'password'} content={undefined} editable={true} />

                        <Divider
                            sx={{
                                marginBottom: 2,
                                borderBottomWidth: 2,
                                background: "black",
                                width: "100%",
                                //PUT A "IF MOBILE" HERE FOR THE WIDTH
                            }}
                        />

                        <ItemStack title={"Trustap Link"} content={undefined} />

                        {
                            userData.trustapFull ?
                                <>
                                    Your Kashd and Trustap accounts are linked. No further action required
                                </>
                                :
                                <Stack direction={"column"}>
                                    <>
                                        You are not registered with Trustap.
                                    </>

                                    <Button
                                        className="trustap-button"
                                        sx={{
                                            width: "20vw",
                                            minWidth: "200px",
                                            height: "8vh",
                                            backgroundColor: "black",
                                            cursor: "pointer",
                                            marginTop: 3
                                        }}
                                        href={href}
                                    >
                                        <div className="submit-text">
                                            Reigster with Trustap
                                        </div>
                                    </Button>

                                </Stack>
                        }

                    </Box>
                    :
                    <></>
                }

                <DialogParent content={content} dialogType={dialogType} dialogTitle={dialogTitle} open={dialogOpen} setDialogOpen={setDialogOpen} setContent={setContent} />


            </Grid >

        </>
    )
}