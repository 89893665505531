import React from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useMediaQuery } from "react-responsive";
import { Grid, Box, } from "@mui/material";

export default function Stepper({ direction, active, clickFunction }) {


    const forward = direction == "forwards" ? true : false

    const isMobile = useMediaQuery({
        query: '(max-device-width: 900px)',
    });

    const desktopInactiveStepper = {
        "bar": {
            background: "#e6e6e6",
            width: "5vw",
            height: "calc(100vh)",
            position: "fixed",
            right: forward ? 0 : "auto",
            left: forward ? "auto" : 0,
            bottom: 0,
            zIndex: -1,
            boxShadow: 4,
        },
        "arrow": {
            position: "fixed",
            color: "white",
            bottom: "50%",
            zIndex: 3
        }
    }

    const desktopActiveStepper = {
        "bar": {
            background: "#505250",
            width: "5vw",
            height: "calc(100vh)",
            position: "fixed",
            right: forward ? 0 : "auto",
            left: forward ? "auto" : 0,
            bottom: 0,
            zIndex: -1,
            boxShadow: 4,
            cursor: "pointer"

        },
        "arrow": {
            position: "fixed",
            color: "white",
            bottom: "50%",
            zIndex: 3
        }
    }


    const mobileInactiveStepper = {
        "bar": {
            background: "#e6e6e6",
            width: "50vw",
            height: "60px",
            //height: "calc(6vh)",
            //minHeight: "50px",
            position: "fixed",
            right: forward ? 0 : "auto",
            left: forward ? "auto" : 0,
            top: 56,
            zIndex: 1,
            boxShadow: 0,
        },
        "arrow": {
            position: "fixed",
            color: "black",
            top: "75px",
        }
    }

    const mobileActiveStepper = {
        "bar": {
            background: "#505250",
            width: "50vw",
            height: "60px",
            //height: "calc(6vh)",
            //minHeight: "50px",
            position: "fixed",
            right: forward ? 0 : "auto",
            left: forward ? "auto" : 0,
            top: 56,
            zIndex: 1,
            boxShadow: 0,
            cursor: "pointer",
            mb: 5
        },
        "arrow": {
            position: "fixed",
            color: "white",
            top: "75px",
        }
    }

    let activeStepper = isMobile ? mobileActiveStepper : desktopActiveStepper
    let inactiveStepper = isMobile ? mobileInactiveStepper : desktopInactiveStepper

    const icon = direction == "forwards"
        ?
        <ArrowForwardIosIcon
            sx={active == true ? activeStepper.arrow : inactiveStepper.arrow}
        />
        :
        <ArrowBackIosIcon
            sx={active == true ? activeStepper.arrow : inactiveStepper.arrow}
        />

    const handleClick = active ? clickFunction : undefined

    return (

        <Box
            sx={active == true ? activeStepper.bar : inactiveStepper.bar}
            onClick={() => handleClick()}

        >
            <Grid
                container
                direction={"center"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                {icon}
            </Grid>
        </Box>

    )

}