import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import AllColumns from "../Dashboard/TableColumns/AllColumns";
import './datagrid.css'

import { useNavigate } from "react-router-dom";

export default function DashboardDatagrid(props) {

    const navigate = useNavigate();


    //const sortedRows = props.userDeals.sort((a, b) => {
    // Convert creation date strings to Date objects
    //    const dateA = new Date(a.creation);
    //    const dateB = new Date(b.creation);

    // Return the difference between the dates
    // For ascending order (oldest first), use dateA - dateB
    // For descending order (newest first), use dateB - dateA
    //    return dateB - dateA;
    //});
    //console.log(sortedRows)

    return (
        <DataGrid
            //onRowClick={(row) => navigate(`/transactionprofile/${row.id}`)}
            onRowClick={(row) => console.log(row)}


            sx={{
                // pointer cursor on ALL rows
                '& .MuiDataGrid-row:hover': {
                    cursor: 'pointer'
                }
            }}

            columns={
                AllColumns(
                    props.reviewDeal,
                    props.setItemUploadOpen,
                    props.setFundsUploadOpen,
                    props.setItemsForUpload,
                    props.setDealTitle,
                    props.setDeal,
                    props.setDealData
                )
            }
            rows={props.userDeals}
        />

    )
}