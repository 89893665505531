import React from "react";
import HeaderSidebar from "../components/HeaderSidebar";
import NotificationsBox from "../components/Notifications/NotificationsBox";

export default function Notifications() {
    return (
        <>
            <HeaderSidebar />
            <NotificationsBox />
        </>
    )
}