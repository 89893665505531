import React from "react";
import { Dialog, Grid, Stack, TextField, Button } from "@mui/material";
import './splash.css'

export default function ForgotPasswordDialog(props) {

    const [userEmail, setUserEmail] = React.useState();
    const [displayOption, setDisplayOption] = React.useState('input');

    const server = process.env.REACT_APP_SERVER_ADDRESS


    const display = {
        'input':
            <TextField
                value={userEmail}
                onInput={e => setUserEmail(e.target.value)}
                sx={{ marginBottom: 5 }}
                id="standard-basic" variant="standard"
            />,
        'success':
            <div>
                Reset instructions have been emailed to {userEmail}
            </div>
    }



    const handleSubmit = async (event) => {
        event.preventDefault();

        const resp = await fetch(`${server}/user/resetpassword`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "email": userEmail,
            })
        });

        if (resp.status !== 200) {
            alert('There has been an error');
            return false;
        }
        else {
            setDisplayOption('success')
            //props.setPasswordOpen(false)
        }
    }


    const handleClose = () => {
        props.setPasswordOpen(false)
        setDisplayOption('input')
        setUserEmail('')
    }



    return (

        <Dialog
            open={props.passwordOpen}
            onClose={() => handleClose()}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <form onSubmit={handleSubmit} className="login--form">

                <Grid
                    className="login-text"
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        width: '40vw',
                        minWidth: '320px'

                        //width: '30rem',
                        //display: 'flex', // Add this line
                        //alignItems: 'center', // Add this line
                        //justifyContent: 'center', // Add this line
                        //fontSize: '2rem'
                    }}
                >

                    <h2
                        style={{ margin: 20 }}
                    >
                        Password Reset
                    </h2>


                    <Stack
                        direction={'column'}
                        sx={{
                            margin: 0,
                            width: "80%"
                        }}
                        justifyContent={'flex-start'}
                    >

                        <p style={{ margin: 2 }}>Email Address</p>

                        {display[displayOption]}

                    </Stack>
                </Grid>
                <Button
                    className="login-button"
                    type="submit"
                    sx={{
                        width: "100%",
                        height: "10vh",
                        backgroundColor: "black"
                    }}
                >
                    <h3
                        className="submit-text"

                    >Submit</h3>
                </Button>
            </form>

        </Dialog>
    )
}
