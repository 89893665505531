import React, { useEffect } from "react";
import {
    useNavigate
} from "react-router-dom";

export default function Protection(props) {

    const navigate = useNavigate()

    const [credentials, setCredentials] = React.useState()
    const allowedRoles = props.allowedRoles //Array of allowed user roles is passed into the function

    const server = process.env.REACT_APP_SERVER_ADDRESS

    useEffect(() => {

        /*
        Upon calling, user JWT is send to backend which returns user credentials
        If JWT invalid --> redirect to login
        If role invalid --> redirect to login
        If both valid --> render the desired component
        */

        fetch(`${server}/user/verifytoken`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("access_token")}`,
            },
        })
            .then(
                res => res.json()
            )
            .then(jsondata => {
                setCredentials(jsondata)
            })
    }, [])


    //props.authenticated is the component the user wishes to access and is only rendered when the user meets role requirements
    return (
        <>{
            !credentials ?
                navigate("/login")
                :
                (allowedRoles.includes(credentials.role)) ?
                    props.authenticated :
                    navigate("/login")
        }</>
    )
}

