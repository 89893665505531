import React from "react";
import { Grid } from "@mui/material";

import UserConfig from "./UserConfig";
import ItemConfig from "./ItemConfig";
import MoneyConfig from "./MoneyConfig";
import Review from "./Review";

import { useNavigate } from "react-router-dom";
import { AddNotification } from "../../Notifications/NotificationFunctions";

export default function EscrowOnly(props) {

    const navigate = useNavigate();

    const server = process.env.REACT_APP_SERVER_ADDRESS

    const [escrowStep, setEscrowStep] = React.useState(0)

    const currentUser = sessionStorage.getItem('user_id')

    const [instUser, setInstUser] = React.useState(false);


    const [formData, setFormData] = React.useState({
        title: null,
        iteration: props.contractIteration ? props.contractIteration : 1,
        author: currentUser,
        dateCreated: new Date().toJSON(),
        dealType: "escrowOnly",
        otherParty: null,
        otherPartyInst: null,
        inst: null,
        revealUsername: true,
        revealEmail: true,
        revealName: false,
        revealPhone: false,
        paymentType: "oncompletion",
        upfrontPayment: null,
        authorRole: null,
        dueDate: null,
        items: [{ 'description': null, 'quantity': null, 'unitCost': null }],
        buyerPaysFees: true,
        escrowPayment: 0,
        depositIncluded: false
    })



    //Causing problems on heroku
    const handleSubmit = () => {


        fetch(`${server}/deal/newescrowonly`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "author": formData.author,
                "authorRole": formData.authorRole,
                "buyerPaysFees": formData.buyerPaysFees,
                "dateCreated": formData.dateCreated,
                "dealType": formData.dealType,
                "depositIncluded": formData.depositIncluded,
                "dueDate": formData.dueDate,
                "escrowPayment": formData.escrowPayment,
                "inst": formData.inst,
                "items": formData.items,
                "iteration": formData.iteration,
                "otherParty": formData.otherParty,
                "otherPartyInst": formData.otherPartyInst,
                "paymentType": formData.paymentType,
                "revealEmail": formData.revealEmail,
                "revealName": formData.revealName,
                "revealPhone": formData.revealPhone,
                "revealUsername": formData.revealUsername,
                "title": formData.title,
                "upfrontPayment": formData.upfrontPayment,
            })
        })
            .then(resp => resp.json())
            .then(data => AddNotification(data.data.username, `Invited to review ${formData.title}`, data.data.deal, `/review/${data.data.deal}`, 'invitation'))
            .then(navigate('/'))

    }

    function pageDisplay(page) {
        const screen = {
            0:
                <UserConfig
                    formData={formData}
                    instUser={instUser}
                    setInstUser={setInstUser}
                    setFormData={setFormData}
                    setEscrowStep={setEscrowStep}
                    setPage={props.setPage}
                />
            ,
            1:
                <ItemConfig
                    formData={formData}
                    setFormData={setFormData}
                    setEscrowStep={setEscrowStep}
                />
            ,

            2:
                <MoneyConfig
                    formData={formData}
                    setFormData={setFormData}
                    setEscrowStep={setEscrowStep}
                />
            ,


            3:
                <Review
                    formData={formData}
                    setFormData={setFormData}
                    setEscrowStep={setEscrowStep}
                    handleSubmit={handleSubmit}
                    setPage={props.setPage}
                />
        }
        return screen[page]
    }



    //Set Components for each page change with the step selcted in the stepper displaying it
    //Send form from THIS page rather than passing props back through again



    return (
        <>

            <Grid
                container
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}

            >

                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        width: "85vw",
                        zIndex: 1,
                    }}
                >
                    {pageDisplay(escrowStep)}
                </Grid>

            </Grid >
        </>
    )
}

//           <EscrowStepper setPage={props.setPage} setEscrowStep={setEscrowStep} handleSubmit={handleSubmit} />
