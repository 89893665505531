import React from "react";
import { Box, Grid, Stack, Paper, Divider } from "@mui/material";
import './dealprofile.css'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import './dealprofile.css'

export default function EscrowStatus(props) {

    const [pending, setPending] = React.useState([1]);
    const [approved, setApproved] = React.useState([1]);

    const dealType = props.dealData.dealType
    const escrowPaid = props.dealData.escrowPaid


    React.useEffect(() => {
        if (!props.dealData.payments) {
            return undefined
        }

        const payments = props.dealData.payments

        const pendingList = payments.filter((i) => i.statuses.length == 1) //If one status in list (the initial pending status..)
        const pendingFormatted = pendingList.map((i) => ({ "amount": i.amount, "date": i.statuses[0].date, "status": "Pending" })) //...Reformat with the amount next to details
        console.log(pendingFormatted)

        setPending(pendingFormatted)

        const approvedList = payments.filter((i) => i.statuses.length == 2)
        let approvedFormattedList = []
        for (let i = 0; i < approvedList.length; i++) {
            const statuses = approvedList[i].statuses
            const submissionDate = statuses.filter((i) => i.status == "pending")[0]["date"]

            approvedFormattedList.push({ "amount": approvedList[i].amount, "date": submissionDate, "status": "Approved" })
        }
        setApproved(approvedFormattedList)

    }, [props.dealData.payments])





    const getQuant = (item) => {
        let count = 0;

        if (!item.files) {
            return count
        }

        item.files.forEach((file) => {
            count += file.quantity;
        });

        return count
    };


    return (

        <Box
            sx={{
                marginY: 2,
            }}
        >
            <Paper elevation={3}>

                <Stack
                    className="box-title"
                    sx={{
                        background: "black"
                    }}
                    direction={"row"}
                    justifyContent={"space-between"}
                >
                    <div className="profile-header">Escrow Status</div>
                </Stack>


                <Stack
                    direction={"column"}
                    sx={{
                        paddingX: 1.5
                    }}
                >

                    <Box
                        sx={{
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <Stack
                            direction={"column"}
                        >

                            {/* buyerComplete, sellerComplete */}

                            <div className="escrow-status-text">
                                {props.dealData.paymentType == "oncompletion" ?
                                    <div className="grey-text">No Upfront Payment</div>
                                    :
                                    props.dealData.upfrontPaid ?
                                        <div className="green-text">Upfront Payment Paid</div>
                                        :
                                        <div className="red-text">Upfront Payment Unpaid</div>
                                }
                            </div>


                            <Divider />

                            <div className="escrow-status-text">
                                {props.dealData.escrowPaid ?
                                    <div className="green-text">Funds In Escrow</div>
                                    :
                                    <div className="red-text">Funds Not In Escrow</div>
                                }
                            </div>


                            <Divider />


                            <div className="escrow-status-text">
                                {props.dealData.sellerComplete ?
                                    <div className="green-text">Items In Escrow</div>
                                    :
                                    <div className="red-text">Items Not In Escrow</div>
                                }
                            </div>


                        </Stack>
                    </Box>


                </Stack >

            </Paper >
        </Box >

    )

}

