import React from 'react'

import Grid from '@mui/material/Grid';
import { createTheme } from "@mui/material/styles";
import { alpha } from "@mui/material";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';


export default function RegisterPageTwo({ existingCredentials, formData, setFormData, handleSubmit }) {

    const theme = createTheme({
        palette: {
            loginBox: {
                background: alpha('#ffffff', 0.8),
            }
        },
    })

    const passwordMismatch = formData.confirmPassword && formData.confirmPassword != formData.password
    const usernameTaken = existingCredentials.usernames.includes(formData.username)
    const emailTaken = existingCredentials.emails.includes(formData.email)
    const submitDisabled = !formData.email || !formData.password || !formData.confirmPassword || passwordMismatch || usernameTaken || emailTaken



    return (
        <form>

            <Grid
                className="login-text"
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                    width: '30vw',
                    minWidth: '320px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '1.5rem'
                }}
            >
                <h2
                    style={{ margin: 20 }}
                >
                    Register
                </h2>

                <Stack
                    direction={'column'}
                    sx={{
                        margin: 0,
                        width: "80%"
                    }}
                    justifyContent={'flex-start'}
                >

                    <p style={{ margin: 2 }}>Email Address</p>

                    <TextField
                        error={emailTaken}
                        helperText={emailTaken ? "Email address already registered" : ""}
                        value={formData.email}
                        onInput={(event) => { setFormData((prevData) => ({ ...prevData, email: event.target.value })) }}
                        sx={{ marginBottom: 5 }}
                        id="standard-basic" variant="standard"
                    />

                    <p style={{ margin: 2 }}>Username</p>

                    <TextField
                        error={usernameTaken}
                        helperText={usernameTaken ? "Username unavailable" : ""}
                        value={formData.username}
                        onInput={(event) => { setFormData((prevData) => ({ ...prevData, username: event.target.value })) }}
                        sx={{ marginBottom: 5 }}
                        id="standard-basic" variant="standard"
                    />

                    <p style={{ margin: 2 }}>Password</p>

                    <TextField
                        value={formData.password}
                        onInput={(event) => { setFormData((prevData) => ({ ...prevData, password: event.target.value })) }}
                        sx={{ marginBottom: 5 }}
                        id="standard-basic" variant="standard" type="password"
                    />

                    <p style={{ margin: 2 }}>Confirm Password</p>

                    <TextField
                        error={passwordMismatch}
                        helperText={passwordMismatch ? "Passwords do not match" : ""}
                        value={formData.confirmPassword}
                        onInput={(event) => { setFormData((prevData) => ({ ...prevData, confirmPassword: event.target.value })) }}
                        sx={{ marginBottom: 5 }}
                        id="standard-basic" variant="standard" type="password"
                    />



                </Stack>


            </Grid>

            <Button
                className="login-button"
                type="submit"
                disabled={submitDisabled}
                sx={{
                    width: "100%",
                    height: "10vh",
                    backgroundColor: submitDisabled ? "grey" : "black"
                }}
                onClick={(e) => (
                    handleSubmit(e)
                )}
            >
                <div
                    className="submit-text"
                >
                    Submit
                </div>
            </Button>

        </form>


    )
}

