import React from "react";
import { Dialog, TextField, DialogActions, Button, Stack, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import './splash.css'

import { useDispatch } from 'react-redux';

import { GetNotifications } from "../Notifications/NotificationFunctions";
import { setNotfications } from "../../store/notificationSlice";



export default function LoginDialog(props) {

    const server = process.env.REACT_APP_SERVER_ADDRESS

    const dispatch = useDispatch();

    const [email, setEmail] = React.useState();
    const [password, setPassword] = React.useState();
    const [errorText, setErrorText] = React.useState();

    const navigate = useNavigate();


    const fetchNotifications = (id) => {
        console.log('running')
        GetNotifications(id)
            .then(notifs => {
                console.log(notifs);
                dispatch(setNotfications({ notifications: notifs }));
            })
            .catch(error => {
                console.error(error);
            });
    }

    const handleSubmit = async (event) => {
        console.log(email, password)
        event.preventDefault();

        const resp = await fetch(`${server}/user/login`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "email": email,
                "password": password
            })
        });

        if (resp.status == 401) {
            setErrorText('Invalid login credentials');
        }

        else if (resp.status !== 200) {
            setErrorText('Currently unable to log in - server error');
        }

        else {
            const data = await resp.json();
            sessionStorage.setItem("access_token", data.data.access_token);
            sessionStorage.setItem("user_id", data.data.user_id);
            sessionStorage.setItem("user_name", data.data.user_name);

            fetchNotifications(data.data.user_id);

            navigate('/');
        }


    }


    const handleClose = () => {
        props.setLoginOpen(false)
        setErrorText(undefined)

    }

    return (

        <Dialog
            //className="login-text"
            open={props.loginOpen}
            onClose={handleClose}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            <form onSubmit={handleSubmit} className="login--form">

                <Grid
                    className="login-text"
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        width: '30vw',
                        minWidth: '320px'
                        //display: 'flex', // Add this line
                        //alignItems: 'center', // Add this line
                        //justifyContent: 'center', // Add this line
                        //fontSize: '2rem'
                    }}
                >

                    <h2
                        style={{ margin: 20 }}
                    >
                        Login
                    </h2>

                    {
                        errorText
                            ?
                            <div
                                className="error-text"
                            >
                                {errorText}
                            </div>
                            :
                            <></>
                    }


                    <Stack
                        direction={'column'}
                        sx={{
                            margin: 0,
                            width: "80%"
                        }}
                        justifyContent={'flex-start'}
                    >

                        <p style={{ margin: 2 }}>Email</p>

                        <TextField
                            value={email}
                            onInput={e => setEmail(e.target.value)}
                            sx={{ marginBottom: 5 }}
                            id="standard-basic" variant="standard"
                        />

                        <p style={{ margin: 2 }}>Password</p>

                        <TextField
                            value={password}
                            onInput={e => setPassword(e.target.value)}
                            sx={{ marginBottom: 5 }}
                            id="standard-basic" variant="standard" type="password"
                        />


                    </Stack>
                </Grid>
                <Button
                    className="login-button"
                    type="submit"
                    sx={{
                        width: "100%",
                        height: "10vh",
                        backgroundColor: "black"
                    }}
                >
                    <div
                        className="submit-text"
                    >
                        Submit
                    </div>
                </Button>
            </form>

        </Dialog>
    )
}

