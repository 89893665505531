import { createSlice } from '@reduxjs/toolkit'

export const notificationSlice = createSlice({
    name: 'notifications',
    initialState: {
        notifications: undefined,
    },
    reducers: {
        setNotfications: (state, action) => {
            state.notifications = action.payload.notifications;
        },
    },
})

// Action creators are generated for each case reducer function
export const { setNotfications } = notificationSlice.actions

export default notificationSlice.reducer


