import React from "react"
import { Box, FormControl } from "@mui/material"
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from "@mui/material/Button";
import CancelIcon from '@mui/icons-material/Cancel';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

import { useMediaQuery } from "react-responsive";

import { Stack } from "@mui/material";

import './escrow.css'


import TextField from "@mui/material/TextField";

import Stepper from "../Stepper";


export default function ItemConfig(props) {


    const [itemCount, setItemCount] = React.useState(0);
    const [cost, setCost] = React.useState(0);
    //const [depositIncluded, setDepositIncluded] = React.useState(props.formData.de);
    const [escrow, setEscrow] = React.useState();

    React.useEffect(() => {
        const existing = props.formData.items
        console.log(existing)
        console.log(cost)

        const sumOfItems = existing.reduce(
            (accumulator, currentItem) => accumulator + (currentItem.quantity * currentItem.unitCost),
            0
        );

        setCost(sumOfItems)

    }, [props.formData.items])


    function addItem() {
        const existing = [...props.formData.items]
        existing.push({ 'description': null, 'quantity': null, 'unitCost': null })
        console.log(existing)
        setItemCount((prev) => prev++)
        props.setFormData({ ...props.formData, items: existing })
    }

    //This fuction passes the data back up to the escrowonly form
    //Alterations made in the UI change the state of the form based on indexing
    function alterRow(index, field, data) {
        const existing = [...props.formData.items]
        existing[index][field] = data
        props.setFormData({ ...props.formData, items: existing })
    }

    function removeRow(index) {
        const existing = [...props.formData.items]
        existing.splice(index, 1)
        props.setFormData({ ...props.formData, items: existing })
    }


    const nextPage = () => {
        props.setEscrowStep(currentStep => currentStep + 1)
    }

    const prevPage = () => {
        props.setEscrowStep(currentStep => currentStep - 1)
    }

    const isMobile = useMediaQuery({
        query: '(max-device-width: 900px)',
    });


    const calculateTotal = (upfrontAmount, itemCost) => {

        if (props.formData.depositIncluded) {
            return parseFloat(upfrontAmount) + parseFloat(itemCost)
        }
        else {
            if (!itemCost) {
                return parseFloat(upfrontAmount)
            }
            return parseFloat(upfrontAmount) + parseFloat(itemCost)
        }
    }




    const handleChange = (val) => {
        props.setFormData({ ...props.formData, depositIncluded: val });
        //setDepositIncluded(val);
    }



    return (
        <>

            <Stepper direction={'backwards'} active={true} clickFunction={prevPage} />

            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
            >

                <Stack
                    direction="column"
                    sx={{
                        marginTop: isMobile ? 10 : 5,
                        width: "90%"
                    }}
                >
                    <div className="title-text">Item Configuration</div>

                </Stack>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width="75%"><h4>Item Description</h4></TableCell>
                            <TableCell><h4>Quantity</h4></TableCell>
                            <TableCell><h4>Unit Cost</h4></TableCell>
                            <TableCell><h4>Remove</h4></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.formData.items.map((entry, index) =>

                            <TableRow key={index}
                            >
                                <TableCell
                                    width="75%"
                                    sx={{
                                        borderBottom: "none"
                                    }}
                                >
                                    <TextField
                                        id="standard-basic" variant="standard"
                                        sx={{
                                            width: "90%"
                                        }}
                                        value={props.formData.items[index].description}
                                        onChange={(e) => {
                                            alterRow(index, 'description', e.target.value);
                                        }}
                                    />
                                </TableCell>
                                <TableCell
                                    sx={{
                                        borderBottom: "none"
                                    }}
                                >
                                    <TextField
                                        variant="standard"
                                        type="number"
                                        sx={{
                                            width: "90%",
                                            border: 0
                                        }}
                                        value={props.formData.items[index].quantity}
                                        onChange={(e) => {
                                            alterRow(index, 'quantity', e.target.value);
                                        }}
                                    />
                                </TableCell>
                                <TableCell

                                    sx={{
                                        borderBottom: "none"
                                    }}>
                                    <TextField
                                        variant="standard"
                                        type="number"
                                        sx={{
                                            width: "90%"
                                        }}
                                        value={props.formData.items[index].unitCost}
                                        onChange={(e) => {
                                            alterRow(index, 'unitCost', e.target.value);
                                        }}
                                    />
                                </TableCell>
                                <TableCell

                                    sx={{
                                        borderBottom: "none"
                                    }}>
                                    <CancelIcon
                                        sx={{ cursor: "pointer" }}
                                        onClick={() =>
                                            removeRow(index)
                                        }
                                    />
                                </TableCell>
                            </TableRow>

                        )}
                    </TableBody>
                </Table>





                {/*
                <Stack
                    direction="column"
                    sx={{
                        marginTop: isMobile ? 10 : 5,
                        width: "90%"
                    }}
                >
                    <div className="title-text">Cost Summary</div>

                </Stack>

                <Stack
                    sx={{
                        width: "100%",
                    }}
                >

                    {props.formData.paymentType == "upfront"
                        ?
                        <div>
                            <div className="summary-row"> <span className="summary-header">Upfront Cost</span> <span>£{props.formData.upfrontPayment}</span> </div>

                            <div className="summary-subrow">

                                <FormControl variant="standard" sx={{ minWidth: 120 }}>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        label="Age"
                                        value={props.formData.depositIncluded ? true : false}
                                        onChange={(e) => (handleChange(e.target.value))}
                                        sx={{
                                            padding: 0,
                                        }}
                                    >
                                        <MenuItem value={true}><span className="summary-subheader">Upfront pays towards item cost</span></MenuItem>
                                        <MenuItem value={false}><span className="summary-subheader">Upfront is in addition to item cost</span></MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        :
                        <></>
                    }



                    {
                        cost ? <div className="summary-row"> <span className="summary-header">Escrow Amount</span> <span>£{escrow}</span> </div> : <></>
                    }


                    {props.formData.paymentType == "upfront"
                        ?
                        <div className="summary-row"> <span className="summary-header">Total Cost</span> <span>£{calculateTotal(props.formData.upfrontPayment, calculateEscrow(props.formData.upfrontPayment, cost))}</span> </div>
                        :
                        <></>
                    }

                </Stack>
                */}




                <Button
                    className="add-item-button"
                    onClick={() => addItem()}
                    sx={{
                        margin: 2,
                        width: "40%",
                        height: "6vh",
                        backgroundColor: "black",
                        color: "white"
                    }}
                >
                    <div className="item-button-text">Add Additional Item</div>
                </Button>

            </Grid>
            <Stepper direction={'forwards'} active={true} clickFunction={nextPage} />

        </>

    )
}
