import React from "react";
import { Button, Box } from "@mui/material";
import './dealprofile.css'

export default function BuyerClaimButton(props) {

    const server = process.env.REACT_APP_SERVER_ADDRESS

    const [complete, setComplete] = React.useState(false);
    const [userAgent, setUserAgent] = React.useState(false);
    const [ipAddress, setIpAddress] = React.useState(false);

    React.useEffect(() => {
        setComplete(props.dealData.sellerComplete && props.dealData.buyerComplete)
    }, [props.dealData])

    React.useEffect(() => {
        setUserAgent(JSON.stringify(navigator.userAgent));
    }, []);


    const getIp = async () => {
        try {
            const response = await fetch("https://ipapi.co/json/");
            if (response.ok) {
                const data = await response.json();
                setIpAddress(data.ip);
            } else {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        } catch (error) {
            console.error('Fetch error:', error.message);
        }
    };

    // Run `getIP` function above just once when the page is rendered
    React.useEffect(() => {
        getIp()
    }, [])



    const downloadZip = () => {
        console.log('DOWNLOADING')
        fetch(`${server}/item/download`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("access_token")}`,
                },
                body: JSON.stringify({
                    deal_id: props.dealData.id,
                    user_agent: userAgent,
                    ip_address: ipAddress
                })

            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.blob();
            })
            .then(blob => {
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = `${props.dealData.title}.zip`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            })
            .catch(e => console.error('Fetch error:', e));
    };



    const buttonSx = {
        width: "100%",
        height: "10vh",
        '&:hover': {
            backgroundColor: 'black',
        },
    }

    return (
        <>
            <Box className="info-box">
                Download will become available when both parties have completed
            </Box>

            <Button
                disabled={!complete}
                className="claim-button"
                onClick={() => downloadZip()}
                sx={{
                    ...buttonSx,
                    marginBottom: 2,
                    background: complete ? "black" : "lightgrey"
                }}
            >
                <div className="big-button-text">Download Items</div>
            </Button>
        </>
    )

}