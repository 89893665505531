import React from "react";
import { Button, Box, Dialog, DialogContent, TextField } from "@mui/material";
import './dealprofile.css'

export default function ComplainButton(props) {

    const server = process.env.REACT_APP_SERVER_ADDRESS

    const [userAgent, setUserAgent] = React.useState(false);
    const [ipAddress, setIpAddress] = React.useState(false);
    const [complaintText, setComplaintText] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const [displayCount, setDisplayCount] = React.useState(1);



    React.useEffect(() => {
        setUserAgent(JSON.stringify(navigator.userAgent));
    }, []);


    const getIp = async () => {
        try {
            const response = await fetch("https://ipapi.co/json/");
            if (response.ok) {
                const data = await response.json();
                setIpAddress(data.ip);
            } else {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        } catch (error) {
            console.error('Fetch error:', error.message);
        }
    };

    // Run `getIP` function above just once when the page is rendered
    React.useEffect(() => {
        getIp()
    }, [])



    const submit = () => {
        fetch(`${server}/deal/complaints`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("access_token")}`,
                },
                body: JSON.stringify({
                    deal_id: props.dealData.id,
                    complaint_text: complaintText
                })

            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                else {
                    setOpen(false)
                    setDisplayCount(1)
                    setComplaintText("")
                }
            })

    };



    const buttonSx = {
        width: "100%",
        height: "10vh",
        backgroundColor: "black",
        '&:hover': {
            backgroundColor: 'black',
        },
    }

    return (
        <>


            <Button
                className="claim-button"
                onClick={() => setOpen(true)}
                sx={{
                    ...buttonSx,
                    marginBottom: 2,
                    background: "black"
                }}
            >
                <div className="big-button-text">Lodge Complaint</div>
            </Button>

            <Dialog
                open={open}
                onClose={() => (setOpen(false), setDisplayCount(1))}
            >
                {
                    displayCount == 1
                        ?
                        <DialogContent>
                            <div className="complaint-warning">Lodging a complaint is a last resort. Please ensure you have discussed with the other party prior to escalation.</div>
                            <Button
                                className="claim-button"
                                onClick={() => setDisplayCount(2)}
                                sx={{
                                    ...buttonSx,
                                    marginBottom: 2,
                                }}
                            >
                                <div className="big-button-text">Understood</div>
                            </Button>
                        </DialogContent>


                        :


                        <DialogContent>

                            <div className="complaint-warning">Please provide a short description of the complaint</div>

                            <TextField
                                multiline
                                value={complaintText}
                                onChange={(e) => setComplaintText(e.target.value)}
                                sx={{
                                    width: "100%",
                                    height: "10rem",
                                    marginBottom: "2rem"
                                }}

                                inputProps={{
                                    style: {
                                        height: "9rem"
                                    }
                                }}
                            />

                            <Button
                                className="claim-button"
                                onClick={() => submit()}
                                sx={{
                                    ...buttonSx,
                                    marginBottom: 2,
                                }}
                            >
                                <div className="big-button-text">Submit</div>
                            </Button>

                        </DialogContent>
                }

            </Dialog>
        </>
    )

}