import { PlaidLink } from "react-plaid-link";
import React from "react";
import './trustap.css'
import { Button } from "@mui/material";
import { json } from "react-router-dom";


export default function PaymentButton(props) {

    const server = process.env.REACT_APP_SERVER_ADDRESS

    const [token, setToken] = React.useState();
    const [paymentId, setPaymentId] = React.useState();
    const [databaseForm, setDatabaseForm] = React.useState();
    const [confirmed, setConfirmed] = React.useState(false);

    const submitPayment = () => {
        fetch(`${server}/trustap/createpayment`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("access_token")}`,
            },
            body: JSON.stringify({
                amount: props.amount,
                deal: props.deal,
                type: props.type
            }),
        })
            .then(resp => {
                if (!resp.ok) {
                    throw new Error(`HTTP error!`)
                }
                else return resp.json()
            })
            .then(jsondata => {
                const paymentId = jsondata.data.paymentId;
                //window.location.href = `https://actions.stage.trustap.com/online/transactions/${paymentId}/guest_pay/?redirect_uri=https://www.kashd.co/`;
                window.location.href = `https://actions.stage.trustap.com/f2f/transactions/${paymentId}/pay_deposit?redirect_uri=https://www.kashd.co/trustap-payment/&state=state`
            })
    }


    const logData = {
        amount: props.amount,
        dealTitle: props.dealTitle,
        deal: props.deal,
        dealData: props.dealData
    }


    return (

        <Button
            disabled={props.disabled || props.paid}
            onClick={() => submitPayment()}
            //setConfirmed(true)}

            style={{
                "background": props.disabled || props.paid ? "lightgrey" : "black",
                "margin": 0,
                "width": "100%",
                "height": "4rem",
                "cursor": "pointer",
                "borderRadius": 0.2,
                "margin-bottom": "1rem"
            }}>
            <div className="plaid-text">{props.title}</div>

        </Button>
    );
}