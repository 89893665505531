import React from "react";
import { Stack, Grid, Box } from "@mui/material";
import './splash.css'

export default function SplashBaseTwo(props) {

    function changeBackground(e) {
        e.target.style.color = 'red';
    }

    function defaultBackground(e) {
        e.target.style.color = 'black';
    }

    return (
        <div
            style={{
                display: 'flex', // Set the parent container to flex
                alignItems: 'center', // Vertically center children
                justifyContent: 'center', // Horizontally center children
                minHeight: '100vh', // Make sure the container covers at least the viewport height
            }}
        >
            <Grid
                className="splash-text"
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                    minHeight: '100vh',
                    width: '100vw',
                    display: 'flex', // Add this line
                    alignItems: 'center', // Add this line
                    justifyContent: 'center', // Add this line
                }}
            >

                <div
                    className="kashd-splash-text"
                /*
                style={{
                    margin: 0,
                    cursor: 'default',
                    width: "100%",
                    textAlign: 'center', // Center the text horizontally

                }}*/
                >
                    KASHD
                </div>

                <Stack
                    direction={'row'}
                    justifyContent="center"
                    alignItems="center"
                    spacing={5}
                >
                    <div
                        className="login-register-text"
                        onClick={() => (props.setLoginOpen(true))}
                        //onClick={() => (console.log(true))}
                        onMouseOver={changeBackground}
                        onMouseLeave={defaultBackground}
                        style={{ cursor: 'pointer' }}
                    >
                        Login
                    </div>

                    <div
                        className="login-register-text"
                        onClick={() => props.setRegisterOpen(true)}
                        onMouseOver={changeBackground}
                        onMouseLeave={defaultBackground}
                        style={{ cursor: 'pointer' }}
                    >
                        Register
                    </div>


                </Stack>

                <Box
                    className="forgot-password"
                    onClick={() => props.setPasswordOpen(true)}
                    onMouseOver={changeBackground}
                    onMouseLeave={defaultBackground}
                    sx={{
                        position: "absolute",
                        bottom: 40,
                        cursor: "pointer"
                    }}
                >
                    Forgot Your Password?
                </Box>

            </Grid>


        </div>
    )
}
