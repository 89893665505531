import React from "react";
import { Box, Stack, Paper } from "@mui/material";

import './dealprofile.css'

export default function TransactionDetails(props) {

    console.log(props.detailsArray)

    const formatDate = (dateString) => {
        const date = new Date(dateString);

        // Extract the day, month, and year from the Date object
        const day = ("0" + date.getDate()).slice(-2); // Add leading zero if needed
        const month = ("0" + (date.getMonth() + 1)).slice(-2); // Add leading zero if needed, month is 0-indexed
        const year = date.getFullYear();

        // Concatenate in DD/MM/YYYY format
        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate
    }


    return (

        <Box
            sx={{
                marginY: 2,
                width: "100%"
            }}
        >
            <Paper elevation={3}>

                <Stack
                    className="box-title"
                    sx={{
                        background: "black"
                    }}
                >
                    <div className="profile-header">{props.title}</div>
                </Stack>

                <Stack
                    direction={"column"}
                    sx={{
                        marginY: 1,
                        padding: 1.5
                    }}
                >

                    {
                        props.detailsArray.map((item, key) => (
                            item.value
                                ?
                                <>
                                    <div key={key} className="box-head">{item.key}</div>

                                    <div key={key} className="box-text">{item.value}</div>
                                </>
                                :
                                <></>

                        )
                        )
                    }

                </Stack>
            </Paper>

        </Box>

    )

}