import React from "react";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { Grid, Box, } from "@mui/material";

export default function Stepper({ direction, active, clickFunction }) {


    const forward = direction == "forwards" ? true : false

    const inactiveStepper = {
        "bar": {
            background: "#e6e6e6",
            width: "5vw",
            height: "calc(100vh)",
            position: "fixed",
            right: forward ? 0 : "auto",
            left: forward ? "auto" : 0,
            bottom: 0,
            zIndex: -1,
            boxShadow: 4,
        },
        "arrow": {
            position: "fixed",
            color: "white",
            bottom: "50%",
            zIndex: 3
        }
    }

    const activeStepper = {
        "bar": {
            background: "#505250",
            width: "5vw",
            height: "calc(100vh)",
            position: "fixed",
            right: forward ? 0 : "auto",
            left: forward ? "auto" : 0,
            bottom: 0,
            zIndex: -1,
            boxShadow: 4,
            cursor: "pointer"

        },
        "arrow": {
            position: "fixed",
            color: "white",
            bottom: "50%",
            zIndex: 3
        }
    }

    const icon = direction == "forwards"
        ?
        <ArrowForwardIosIcon
            sx={active == true ? activeStepper.arrow : inactiveStepper.arrow}
        />
        :
        <ArrowBackIosIcon
            sx={active == true ? activeStepper.arrow : inactiveStepper.arrow}
        />

    const handleClick = active ? clickFunction : undefined

    return (

        <Box
            sx={active == true ? activeStepper.bar : inactiveStepper.bar}
            onClick={handleClick}

        >
            <Grid
                container
                direction={"center"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                {icon}
            </Grid>
        </Box>

    )

}