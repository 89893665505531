import React from 'react'
import { useParams, useNavigate } from 'react-router-dom';

import HeaderSidebar from '../components/HeaderSidebar';
import ProfileContainer from '../components/DealProfile/ProfileContainer';
import { current } from '@reduxjs/toolkit';
import { daDK } from '@mui/material/locale';


export default function DealProfile() {

    const server = process.env.REACT_APP_SERVER_ADDRESS

    const [dealData, setDealData] = React.useState({})

    const [otherPartyName, setOtherPartyName] = React.useState(undefined)
    const [otherPartyEmail, setOtherPartyEmail] = React.useState(undefined)
    const [otherPartyUsername, setOtherPartyUsername] = React.useState(undefined)
    const [otherPartyPhone, setOtherPartyPhone] = React.useState(undefined)
    const [otherPartyInst, setOtherPartyInst] = React.useState(undefined)
    const [userAgent, setUserAgent] = React.useState(undefined)

    const [completeClicked, setCompleteClicked] = React.useState(false)


    let navigate = useNavigate();

    const params = useParams();



    const formatDate = (dateString) => {
        const date = new Date(dateString);

        // Extract the day, month, and year from the Date object
        const day = ("0" + date.getDate()).slice(-2); // Add leading zero if needed
        const month = ("0" + (date.getMonth() + 1)).slice(-2); // Add leading zero if needed, month is 0-indexed
        const year = date.getFullYear();

        // Concatenate in DD/MM/YYYY format
        const formattedDate = `${day}/${month}/${year}`;
        return formattedDate
    }


    React.useEffect(() => {
        setUserAgent(JSON.stringify(navigator.userAgent));
    }, []);


    React.useEffect(() => {

        fetch(`${server}/deal/dealprofile`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("access_token")}`,
                },
                body: JSON.stringify({
                    user_id: sessionStorage.getItem("user_id"),
                    deal_id: params.deal_id
                })

            })
            .then(resp => {
                if (resp.status === 200) {
                    return resp.json();
                } else {
                    // Handle non-200 response here
                    //navigate('/')
                    throw new Error('Failed to fetch data');

                }
            })
            .then(jsondata => {
                // Handle the successful response here
                const deal = jsondata.data
                const currentUser = sessionStorage.getItem("user_id")
                let otherParty

                if (currentUser == deal.seller) {
                    otherParty = deal.buyer
                }
                else {
                    otherParty = deal.seller
                }
                deal.otherParty = otherParty
                deal.currentUser = currentUser

                setDealData(deal);
            })
            .catch(error => {
                // Handle any errors here
                console.error(error);
                navigate('/')

            });
    }, [completeClicked])


    //                    AddItems(props.dealData.otherParty, `Items added to deal ${props.dealData.title}`, props.dealData.id, '/')





    React.useEffect(() => {

        if (!dealData) {
            return undefined
        }
        else {

            const userId = sessionStorage.getItem("user_id")

            dealData.dueDate = formatDate(dealData.dueDate)
            dealData.dateAccepted = formatDate(dealData.dateAccepted)


            if (dealData.buyer == userId | dealData.seller == userId) {

                if (dealData.buyer == userId) {
                    setOtherPartyName(dealData.sellerName)
                    setOtherPartyEmail(dealData.sellerEmail)
                    setOtherPartyUsername(dealData.sellerUsername)
                    setOtherPartyPhone(dealData.sellerPhone)
                    setOtherPartyInst(dealData.sellerInst)
                }
                else {
                    setOtherPartyName(dealData.buyerName)
                    setOtherPartyEmail(dealData.buyerEmail)
                    setOtherPartyUsername(dealData.buyerUsername)
                    setOtherPartyPhone(dealData.buyerPhone)
                    setOtherPartyInst(dealData.buyerInst)
                }
            }
        }
    }, [dealData])

    const userId = sessionStorage.getItem("user_id")


    //if (!dealData | !userId) {
    //    return <></>
    //}
    //else {

    if (dealData.buyer == userId | dealData.seller == userId) {
        return (
            <>
                <HeaderSidebar />
                <ProfileContainer
                    username={otherPartyUsername}
                    name={otherPartyName}
                    email={otherPartyEmail}
                    phone={otherPartyPhone}
                    inst={otherPartyInst}
                    buyer={dealData.buyer == userId}
                    seller={dealData.seller == userId}

                    completeClicked={completeClicked}
                    setCompleteClicked={setCompleteClicked}

                    dealData={dealData}
                />
            </>

        )
    }
    else {
        //{ JSON.stringify("Buyer", dealData.buyer, "Seller", dealData.seller, "User", userId) }
        //navigate('/')
        { JSON.stringify({ "BUYER ID": dealData.buyer }) }

        { JSON.stringify({ "USER ID": userId }) }
    }



}
