import React from "react";
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function SidebarOption(props) {

    const navigate = useNavigate();

    return (
        <ListItem key={props.text} disablePadding onClick={() => navigate(props.navigate)}>
            <ListItemButton>
                <ListItemIcon>
                    {props.icon}
                </ListItemIcon>
                <ListItemText primary={props.text} />
            </ListItemButton>
        </ListItem>
    )

}