import React from "react";
import { Box, Grid, Stack, Paper, Divider } from "@mui/material";
import './dealprofile.css'

export default function ConfirmationStatus(props) {

    return (

        <Box
            sx={{
                marginY: 2,
            }}
        >
            <Paper elevation={3}>

                <Stack
                    className="box-title"
                    sx={{
                        background: "black"
                    }}
                    direction={"row"}
                    justifyContent={"space-between"}
                >
                    <div className="profile-header">Completion Status</div>
                </Stack>


                <Stack
                    direction={"column"}
                    sx={{
                        paddingX: 1.5
                    }}
                >

                    <Box
                        sx={{
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <Stack
                            direction={"column"}
                        >

                            <Divider />

                            <div className="escrow-status-text">
                                {props.dealData.buyerComplete ?
                                    <div className="green-text">Buyer has completed</div>
                                    :
                                    <div className="red-text">Awaiting buyer completion</div>
                                }
                            </div>


                            <Divider />


                            <div className="escrow-status-text">
                                {props.dealData.sellerComplete ?
                                    <div className="green-text">Seller has completed</div>
                                    :
                                    <div className="red-text">Awaiting seller completion</div>
                                }
                            </div>


                        </Stack>
                    </Box>


                </Stack >

            </Paper >
        </Box >

    )

}

