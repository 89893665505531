import React from "react";
import { Button, Box, Dialog, Grid } from "@mui/material";
import './dealprofile.css'

export default function CancelButton(props) {

    const server = process.env.REACT_APP_SERVER_ADDRESS

    const [dialogOpen, setDialogOpen] = React.useState(false);

    const cancelDeal = () => {
        fetch(`${server}/deal/cancel`, {
            method: "POST",
            headers: {
                'Accept': 'Application/JSON',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem("access_token")}`
            },
            body: JSON.stringify({
                id: props.dealData.id,
                cancellingUser: sessionStorage.getItem("user_id")
            })
        })
            .then(response => {
                if (response.status == 200) {
                    props.setDialogOpen(false)
                }
                else {
                    console.log(response)
                }
            })
    }

    const buttonSx = {
        width: "100%",
        height: "10vh",
        backgroundColor: "black",
        '&:hover': {
            backgroundColor: 'red',
        },
    }

    const openDialog = () => {
        setDialogOpen(true)
    }

    return (
        <>

            <Button
                className="claim-button"
                onClick={openDialog}
                sx={{
                    ...buttonSx,
                    marginBottom: 2,
                }}
            >
                <div className="big-button-text">Cancel Transaction</div>
            </Button>

            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}

            >
                <Grid
                    container
                    direction={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <div className="confirmation-text">Are you sure? This cannot be undone</div>
                    <Button
                        onClick={() => cancelDeal()}
                        sx={{
                            width: "80%",
                            height: "5vh",
                            backgroundColor: "black",
                            '&:hover': {
                                backgroundColor: 'black',
                            },
                            marginBottom: "1rem"
                        }}>
                        <div className="confirmation-button">Yes</div>
                    </Button>

                    <Button
                        onClick={() => setDialogOpen(false)}
                        sx={{
                            width: "80%",
                            height: "5vh",
                            backgroundColor: "black",
                            '&:hover': {
                                backgroundColor: 'black',
                            },
                            marginBottom: "1rem"
                        }}>
                        <div className="confirmation-button">No</div>
                    </Button>
                </Grid>
            </Dialog>
        </>
    )

}