import React from 'react'
import { useParams } from 'react-router-dom';
import EscrowOnly from '../components/EditDeal/EscrowOnly/EscrowOnly';
import { useNavigate } from 'react-router-dom';

export default function Review() {

    const navigate = useNavigate();

    const server = process.env.REACT_APP_SERVER_ADDRESS

    const [dealData, setDealData] = React.useState()
    const [differences, setDifferences] = React.useState()

    const params = useParams();

    function getDifferences(current, compare) {
        const keys = Object.keys(current)

        const valueLog = {}
        for (let i = 0; i < keys.length; i++) {

            const key = keys[i]

            if (key != "items") {
                const currentVal = current[key]
                const compareVal = compare[key]

                if (currentVal != compareVal) {
                    valueLog[key] = { "current": currentVal, "compare": compareVal }
                }
            }
            else {
                const currentItems = current[key]
                const compareItems = compare[key]

                var currentObj = currentItems.reduce(
                    (obj, item) => Object.assign(obj, { [item.id]: { "description": item.description, "quantity": item.quantity, "unitCost": item.unitCost } }), {});

                const compareObj = compareItems.reduce(
                    (obj, item) => Object.assign(obj, { [item.id]: { "description": item.description, "quantity": item.quantity, "unitCost": item.unitCost } }), {});

                valueLog[key] = { "current": currentObj, "compare": compareObj }
            }
        }
        setDifferences(valueLog)

    }


    React.useEffect(() => {

        //What happens if someone tries to access a deal they're not part of?
        //Or a deal that doesn't exist yet?
        fetch(`${server}/deal/dealiterations`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("access_token")}`,
                },
                body: JSON.stringify({
                    user_id: sessionStorage.getItem("user_id"),
                    deal_id: params.deal_id
                })

            })
            .then(resp => resp.json())
            .then(jsondata => (

                setDealData(jsondata.data),
                getDifferences(jsondata.data.current, jsondata.data.compare)

            ))
    }, [])

    if (!dealData) {
        return <></>
    }
    else if (dealData.current.iterationAuthor == sessionStorage.getItem("user_id")) {
        //If trying to review an iteration you've written - redirect to the deal profile
        navigate(`/transactionprofile/${params.deal_id}`)
    }
    else if (dealData.current.dealType == 'escrowOnly') {
        return <EscrowOnly dealData={dealData} differences={differences} />
    }


}