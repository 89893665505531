import React from "react";
import { Box, Grid, Stack, Paper } from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import './dealprofile.css'

export default function TrustapDetails(props) {

    const approved = props.dealData.completedPayments

    return (

        <Box
            sx={{
                marginY: 2,
            }}
        >
            <Paper elevation={3}>

                <Stack
                    className="box-title"
                    sx={{
                        background: "black"
                    }}
                    direction={"row"}
                    justifyContent={"space-between"}
                >
                    <div className="profile-header">Payments</div>
                    <div>
                        {props.buyer && props.accepted && !props.dealData.cancelled
                            ?
                            <Box
                                className="profile-header"
                                onClick={() => props.setFundsUploadOpen(true)}
                                //onClick={() => console.log('yeah')}
                                sx={{ cursor: "pointer" }}
                            >
                                Add Funds
                            </Box>
                            :
                            ''
                        }
                    </div>
                </Stack>


                <Stack
                    direction={"column"}
                    sx={{
                        marginY: 1,
                        padding: 1.5
                    }}
                >

                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">

                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <div className="table-header">
                                            Amount
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="table-header">
                                            Charge
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="table-header">
                                            Date Paid
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="table-header">
                                            Type
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="table-header">
                                            Status
                                        </div>
                                    </TableCell>

                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    approved
                                        ?
                                        approved.map((item, key) => (
                                            <>
                                                <TableRow
                                                    sx={{
                                                        background: "#f5f5f5"
                                                    }}
                                                >
                                                    <TableCell>
                                                        {item.price}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.charge}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.updated}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.entityTitle.toUpperCase()}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.status.toUpperCase()}
                                                    </TableCell>
                                                </TableRow>

                                            </>
                                        ))
                                        :
                                        <></>
                                }

                            </TableBody>

                        </Table>
                    </TableContainer>



                </Stack>
            </Paper>

        </Box>

    )

}

