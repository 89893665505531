import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import './dealprofile.css'

export default function ReviewButton(props) {
    const navigate = useNavigate();



    const buttonSx = {
        width: "100%",
        height: "10vh",
        backgroundColor: "black",
        '&:hover': {
            backgroundColor: 'red',
        },
    }



    return (
        <>

            <Button
                className="claim-button"
                onClick={() => navigate(`/review/${props.dealId}`)}
                sx={{
                    ...buttonSx,
                    marginBottom: 2,
                }}
            >
                <div className="big-button-text">Review</div>
            </Button>
        </>
    )

}