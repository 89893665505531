// TrustapSignin.js
export function TrustapSignin(code, userId) {

    const server = process.env.REACT_APP_SERVER_ADDRESS

    return fetch(`${server}/user/trustap`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem("access_token")}`,
        },
        body: JSON.stringify({
            code: code,
            userId: userId
        })
    })
        .then(resp => {
            if (!resp.ok) {
                throw new Error('Network response was not ok');
            }
            return resp.json();
        })
        .then(jsondata => jsondata.data)
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
        });
}
