import React from 'react'
import Grid from '@mui/material/Grid';
import { createTheme } from "@mui/material/styles";
import { alpha } from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { CountryList } from './CountryList';
import Button from '@mui/material/Button';
import './splash.css'
import { Stack } from '@mui/material';



export default function RegisterPageOne({ formData, setFormData, setStep, setSuccess, success }) {

    const theme = createTheme({
        palette: {
            loginBox: {
                background: alpha('#ffffff', 0.8),
            }
        },
    })

    return (

        <form>
            <Grid
                className="login-text"
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{

                    width: '30vw',
                    minWidth: '320px',
                    display: 'flex', // Add this line
                    alignItems: 'center', // Add this line
                    justifyContent: 'center', // Add this line
                    fontSize: '1.5rem',
                }}
            >
                <h2
                    style={{ margin: 20 }}
                >
                    Register
                </h2>


                <Stack
                    direction={'column'}
                    sx={{
                        margin: 0,
                        width: "80%"
                    }}
                    justifyContent={'flex-start'}
                >


                    <p style={{ margin: 2 }}>First Name</p>

                    <TextField
                        value={formData.firstname}
                        onInput={(event) => { setFormData((prevData) => ({ ...prevData, firstname: event.target.value })) }}
                        sx={{ marginBottom: 5 }}
                        id="standard-basic" variant="standard"
                    />

                    <p style={{ margin: 2 }}>Last Name</p>

                    <TextField
                        value={formData.lastname}
                        onInput={(event) => { setFormData((prevData) => ({ ...prevData, lastname: event.target.value })) }}
                        sx={{ marginBottom: 5 }}
                        id="standard-basic" variant="standard"
                    />

                    <p style={{ margin: 2 }}>Country</p>

                    <Autocomplete
                        sx={{ marginBottom: 5, width: "100%" }}
                        disablePortal
                        id="country-selection"
                        options={CountryList}
                        getOptionLabel={(option) => option.label} // Use the "label" property as the label for each option
                        getOptionSelected={(option, value) => option.label === value.label} // Prevent typing custom options
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setFormData((prevData) => ({
                                    ...prevData,
                                    country: newValue.alpha2Code, // Set the formData country as the alpha2Code of the selected option
                                }));
                            } else {
                                // Handle the case when the selection is cleared
                                setFormData((prevData) => ({
                                    ...prevData,
                                    country: '', // Set the formData country as an empty string if the selection is cleared
                                }));
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                id="standard-basic" variant="standard"
                                {...params}
                                onChange={(event) => {
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        country: event.target.value,
                                    }));
                                }}
                            />
                        )}
                    />

                    <p style={{ margin: 2 }}>Phone Number</p>



                    <TextField
                        value={formData.phone}
                        onInput={(event) => { setFormData((prevData) => ({ ...prevData, phone: event.target.value })) }}
                        sx={{ marginBottom: 5 }}
                        id="standard-basic" variant="standard"
                    />

                </Stack>
            </Grid>


            <Button
                className="login-button"
                type="submit"
                sx={{
                    width: "100%",
                    height: "10vh",
                    backgroundColor: "black"
                }}
                onClick={() => (
                    setStep(step => step += 1),
                    console.log(formData)
                )}
            >
                <div
                    className="submit-text"
                >
                    Next
                </div>
            </Button>

        </form>
    )
}

