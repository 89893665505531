import React from "react";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import SidebarOption from "./SidebarOption";
import './headerSidebar.css'

import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
import LineAxisIcon from '@mui/icons-material/LineAxis';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Grid } from "@mui/material";

export default function SidebarPersistent({ sidebarOpened, setSidebarOpened }) {

    const sidebarOptions = [
        {
            title: 'Initiate',
            icon: <AddIcon />,
            navigate: '/initiate'
        },
        {
            title: 'Escrow Dashboard',
            icon: <HomeIcon />,
            navigate: '/'
        },
        {
            title: 'Notifications',
            icon: <NotificationsIcon />,
            navigate: '/notifications'
        },
        {
            title: 'Profile',
            icon: <PersonIcon />,
            navigate: '/profile'
        },
    ]

    const drawerWidth = 240;

    return (

        <Drawer

            //Changes to height must be done through PaperProps
            PaperProps={{
                sx: {
                    position: "fixed",
                    bottom: 0,
                    //height: "calc(100vh - 32px) !important",
                    background: "white",
                    marginTop: "64px"

                }
            }}

            sx={{
                position: "relative",
                bottom: 0,
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="left"
            open={sidebarOpened}
        >
            <Grid
                direction={"column"}
                justifyContent={"center"}
                alignContent={"center"}
                sx={{
                    paddingX: "16px",
                    paddingY: "10px"
                }}
            >
                <div
                    className="sidebar-header-text"
                >
                    KASHD
                </div>

                <Divider
                    sx={{ width: "100%" }}
                />
            </Grid>


            <List>
                {sidebarOptions.map((object, index) => (
                    <>
                        <SidebarOption
                            text={object.title}
                            icon={object.icon}
                            navigate={object.navigate}
                        />
                    </>


                ))}
            </List>

        </Drawer>
    )
}