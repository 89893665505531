import React from "react"
import ResponsiveAppBar from "./ResponsiveAppBar"
import Header from "./HeaderSidebar/Header"
import Sidebar from "./HeaderSidebar/Sidebar"
import SidebarPersistent from "./HeaderSidebar/SidebarPersistent"
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux"
import { GetNotifications } from "./Notifications/NotificationFunctions"
import { setNotfications } from "../store/notificationSlice"

export default function HeaderSidebar() {

    const dispatch = useDispatch();

    const [sidebarOpened, setSidebarOpened] = React.useState(false)
    const [notifs, setNotifs] = React.useState();

    //const notifications = useSelector((state) => state.notifications.notifications); // Access the 'notifications' field



    React.useEffect(() => {
        console.log('running')
        GetNotifications(sessionStorage.getItem('user_id'))
            .then(notifs => {
                console.log(notifs);
                dispatch(setNotfications({ notifications: notifs }));
                setNotifs(notifs)
            })
            .catch(error => {
                console.error(error);
            });

    }, [])


    return (
        <>
            <Header setSidebarOpened={setSidebarOpened} notifications={notifs} />
            <SidebarPersistent sidebarOpened={sidebarOpened} setSidebarOpened={setSidebarOpened} />
        </>
    )
}