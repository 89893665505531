import React from 'react'
import HeaderSidebar from '../components/HeaderSidebar';
import { useNavigate } from 'react-router-dom';

import DashboardDatagrid from '../components/KashdDashboard/DashboardDatagrid';
import { TrustapSignin } from "../components/Dashboard/TrustapSignin";

import AddItemDialog from '../components/Dashboard/AddItemDialog';
import AddFundsDialog from '../components/Dashboard/AddFundsDialog';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { Grid, Stack, Button, Box, Popover } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import Loading from '../components/Loading';

import { useLocation } from 'react-router-dom';

import DealTypeSelector from '../components/KashdDashboard/DealTypeSelector';

import './css/Dashboard.css'

export default function MultiDash() {

    const server = process.env.REACT_APP_SERVER_ADDRESS

    const [activityType, setActivityType] = React.useState()
    const [userDeals, setUserDeals] = React.useState()

    const [itemUploadOpen, setItemUploadOpen] = React.useState()
    const [fundsUploadOpen, setFundsUploadOpen] = React.useState()
    const [dealTitle, setDealTitle] = React.useState()
    const [deal, setDeal] = React.useState()

    const [dealData, setDealData] = React.useState();

    const [roleDeals, setRoleDeals] = React.useState();

    const [activeDeals, setActiveDeals] = React.useState();
    const [responseRequired, setResponseRequired] = React.useState();
    const [awaitingResponse, setAwaitingResponse] = React.useState();


    const [activeExpanded, setActiveExpanded] = React.useState(true);
    const [awaitingResponseExpanded, setAwaitingResponseExpanded] = React.useState(false);


    const [role, setRole] = React.useState("All");
    const [status, setStatus] = React.useState("Current");

    const handleChangeRole = (event, newRole) => {
        setRole(newRole);
    };

    const handleChangeStatus = (event, newStatus) => {
        if (newStatus !== null) {
            setStatus(newStatus);
        }
    };

    const [displayDeals, setDisplayDeals] = React.useState();


    const expandActive = () => {
        setActiveExpanded(old => !old)
    }

    const expandAwaitingResponse = () => {
        setAwaitingResponseExpanded(old => !old)
    }




    const [itemsForUpload, setItemsForUpload] = React.useState()

    let navigate = useNavigate();


    function reviewDeal(dealId) {
        navigate('/review/' + dealId);
    }

    function handleItemClose() {
        setItemUploadOpen((oldOpen) => !oldOpen)
    }

    function handleFundsClose() {
        setFundsUploadOpen((oldOpen) => !oldOpen)
    }


    React.useEffect(() => {
        fetch(`${server}/deal/userdeals`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${sessionStorage.getItem("access_token")}`,
                },
                body: JSON.stringify({
                    user_id: sessionStorage.getItem("user_id")
                })

            })
            .then(resp => resp.json())
            .then(jsondata => (
                console.log(jsondata.data),
                setUserDeals(
                    jsondata.data
                )
            ))
    }, [])



    React.useEffect(() => {
        if (!userDeals) {
            return undefined
        }

        if (role == "All") {
            setRoleDeals(userDeals)
        }
        else {
            const filtered = userDeals.filter((obj) => obj.role == role)
            setRoleDeals(filtered)
        }

    }, [userDeals, role])



    React.useEffect(() => {
        if (!roleDeals) {
            return undefined
        }
        const Current = roleDeals.filter((obj) => obj.status == "Active" || obj.status == "Response required" || obj.status == "Awaiting response" || obj.includeInCurrent == true)
        const active = roleDeals.filter((obj) => obj.status == "Active")
        const responseRequired = roleDeals.filter((obj) => obj.status == "Response required")
        const awaitingResponse = userDeals.filter(deal => deal.status === "Awaiting response");


        const Historic = roleDeals.filter((obj) => obj.status == "Complete" || obj.status == "Cancelled")
        const complete = userDeals.filter(deal => deal.status === "Complete");
        const cancelled = userDeals.filter(deal => deal.status === "Cancelled");


        if (status == "Current") {
            setDisplayDeals(Current)
        }
        else if (status == "Historic") {
            setDisplayDeals(Historic)
        }
        else if (status == "Active") {
            setDisplayDeals(active)
        }
        else if (status == "Response Required") {
            setDisplayDeals(responseRequired)
        }
        else if (status == "Awaiting response") {
            setDisplayDeals(awaitingResponse)
        }
        else if (status == "Complete") {
            setDisplayDeals(complete)
        }
        else if (status == "Cancelled") {
            setDisplayDeals(cancelled)
        }

        //setActiveDeals(active)
        //setResponseRequired(responseRequired)
        //setAwaitingResponse(awaiting);



    }, [roleDeals, status])






    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    let params = {};

    for (let [key, value] of query.entries()) {
        params[key] = value;
    }



    //THIS HERE IS THE PROBLEM - THIS IS WRONG


    React.useEffect(() => {
        if (params.hasOwnProperty('code')) {
            TrustapSignin(params.code, params.state).then(data => {
                console.log('THE RESPONSE', data); // Here you can use the data
                // Do something with the data
            })
        }
    }, [params]);


    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        roleDeals ?
            <div>


                <HeaderSidebar />
                <Grid
                    container
                    sx={{
                        background: "white",
                        margin: "30px",
                        height: "calc(100vh - 124px)",
                        width: "calc(100vw - 60px)"
                    }}
                >


                    <Grid className='dashboard-title'
                        container
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        sx={{
                            height: "6rem",
                            width: "95vw",
                            marginBottom: "100px",
                            position: "fixed",
                            top: "60px",
                            background: "white",
                            zIndex: 10
                        }}
                    >
                        <Box>Transaction Dashboard</Box>



                        <ToggleButton onClick={handleClick}> <div className='selector-text'>{status}</div></ToggleButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}

                        >
                            <div style={{ "height": "51.7vh" }}>
                                <ToggleButtonGroup
                                    color="primary"
                                    orientation="vertical"
                                    value={status}
                                    exclusive
                                    onChange={handleChangeStatus}
                                    aria-label="Platform"
                                    sx={{
                                        background: "white"
                                    }}
                                >
                                    <ToggleButton
                                        disabled
                                        sx={{
                                            background: "lightGrey"
                                        }}
                                    >
                                        <div className='selector-text'>Current</div>
                                    </ToggleButton>
                                    <ToggleButton value="Current">
                                        <div className='selector-text'>All - Current</div>
                                    </ToggleButton>
                                    <ToggleButton value="Active">
                                        <div className='selector-text'>Active</div>
                                    </ToggleButton>
                                    <ToggleButton value="Awaiting response">
                                        <div className='selector-text'>Awaiting Response</div>
                                    </ToggleButton>
                                    <ToggleButton value="Response Required">
                                        <div className='selector-text'>Response Required</div>
                                    </ToggleButton>

                                    <ToggleButton
                                        disabled
                                        sx={{
                                            background: "lightGrey"
                                        }}
                                    >
                                        <div className='selector-text'>Historic</div>
                                    </ToggleButton>

                                    <ToggleButton value="Historic">
                                        <div className='selector-text'>All - Historic</div>
                                    </ToggleButton>
                                    <ToggleButton value="Complete">
                                        <div className='selector-text'>Complete</div>
                                    </ToggleButton>
                                    <ToggleButton value="Cancelled">
                                        <div className='selector-text'>Cancelled</div>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                        </Popover>





                        <Stack
                            direction={"column"}
                            alignItems={"center"}
                        >
                            <ToggleButtonGroup
                                color="primary"
                                value={role}
                                exclusive
                                onChange={handleChangeRole}
                                aria-label="Platform"
                                sx={{
                                    height: "3rem"
                                }}
                            >
                                <ToggleButton value="All">
                                    <div className='selector-text'>All</div>
                                </ToggleButton>
                                <ToggleButton value="Buyer">
                                    <div className='selector-text'>Buying</div>
                                </ToggleButton>
                                <ToggleButton value="Seller">
                                    <div className='selector-text'>Selling</div>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Stack>

                    </Grid>

                    <Grid
                        direction={"column"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{
                            marginTop: "70px"
                        }}
                    >

                        <div style={{
                            height: activeExpanded ? "calc(100vh - 200px)" : "auto",
                            width: "95vw",
                            marginBottom: "50px"
                        }}>

                            <Stack
                                direction={"row"}
                                spacing={2}
                                alignItems={"center"}
                            >
                            </Stack>

                            {
                                userDeals
                                    ?
                                    <DashboardDatagrid
                                        reviewDeal={reviewDeal}
                                        setItemUploadOpen={setItemUploadOpen}
                                        setFundsUploadOpen={setFundsUploadOpen}
                                        setItemsForUpload={setItemsForUpload}
                                        setDealTitle={setDealTitle}
                                        setDeal={setDeal}
                                        setDealData={setDealData}

                                        userDeals={displayDeals ? displayDeals : []}
                                    />
                                    :
                                    <></>
                            }
                        </div>



                    </Grid>

                </Grid >
                <AddItemDialog dealData={dealData} open={itemUploadOpen} setOpen={setItemUploadOpen} handleClose={handleItemClose} items={itemsForUpload} setItems={setItemsForUpload} />
                <AddFundsDialog open={fundsUploadOpen} handleClose={handleFundsClose} dealTitle={dealTitle} deal={deal} dealData={dealData} />


            </div >
            :
            <>
                <HeaderSidebar />
                <Grid
                    container
                    justifyContent={"center"}
                    alignContent={"center"}
                    sx={{
                        width: "100vw",
                        height: "calc(100vh - 124px)",
                    }}
                >
                    <Loading />
                </Grid>
            </>
    )
}