import { DialogContent } from "@mui/material"
import React from "react";
import { Dialog, DialogTitle, Button, TextField, Box } from "@mui/material";
import './profile.css'

export default function DialogParent(props) {

    const server = process.env.REACT_APP_SERVER_ADDRESS

    const [password, setPassword] = React.useState('');
    const [currentPassword, setCurrentPassword] = React.useState(undefined);
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [passwordSubmitActive, setPasswordSubmitActive] = React.useState(false);
    const [passwordFailure, setPasswordFailure] = React.useState(false);

    const [formValue, setFormValue] = React.useState('')

    React.useEffect(() => {
        setFormValue(props.content)
    }, [props.content])

    React.useEffect(() => {
        if ((formValue == confirmPassword) && (formValue.length > 0)) {
            setPasswordSubmitActive(true)
        }
        else (
            setPasswordSubmitActive(false)
        )
    }, [formValue, confirmPassword])



    const handleSubmit = (type, data) => {
        fetch(`${server}/user/update`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "updateType": type,
                "updateData": data,
                "userId": sessionStorage.getItem('user_id'),
                "currentPassword": currentPassword
            })
        })
            .then(response => {
                if (response.status == 200) {
                    props.setDialogOpen(false)
                    setPasswordFailure(false)
                }
                else if (response.status == 401) {
                    setConfirmPassword('')
                    setCurrentPassword('')
                    setFormValue('')
                    setPasswordFailure(true)
                }
                else {
                    console.log('Error in submission')
                }
            }
            )
    }


    const selector = {
        'email':
            <TextField
                label="Email Address"
                id="standard-basic" variant="standard"
                sx={{
                    width: "100%",
                    height: "100%"
                }}
                value={formValue}
            />,

        'name':
            <>
                <TextField
                    onInput={e => setFormValue({ ...formValue, firstname: e.target.value })}
                    label="First Name"
                    id="standard-basic" variant="standard"
                    sx={{
                        width: "100%",
                        marginBottom: 2
                    }}
                    value={formValue && formValue.hasOwnProperty("firstname") ? formValue.firstname : formValue}
                />

                <TextField
                    onInput={e => setFormValue({ ...formValue, lastname: e.target.value })}
                    label="Last Name"
                    id="standard-basic" variant="standard"
                    sx={{
                        width: "100%",
                    }}
                    value={formValue && formValue.hasOwnProperty("lastname") ? formValue.lastname : formValue}
                />
            </>,

        'phone':
            <TextField
                label="Phone Number"
                onInput={e => setFormValue(e.target.value)}
                id="standard-basic" variant="standard"
                sx={{
                    width: "100%",
                }}
                value={formValue}
            />,
        'password':
            <>
                <div
                    style={{
                        display: passwordFailure ? 'block' : 'none'
                    }}
                >
                    Incorrect password provided
                </div>
                <TextField
                    label="Current Password"
                    onInput={e => setCurrentPassword(e.target.value)}
                    id="standard-basic" variant="standard" type="password"
                    sx={{
                        width: "100%",
                        marginBottom: 2
                    }}
                    value={currentPassword}
                />

                <TextField
                    label="Password"
                    onInput={e => setFormValue(e.target.value)}
                    id="standard-basic" variant="standard" type="password"
                    sx={{
                        width: "100%",
                        marginBottom: 2
                    }}
                    value={formValue}
                />

                <TextField
                    label="Confirm Password"
                    onInput={e => setConfirmPassword(e.target.value)}
                    id="standard-basic" variant="standard" type="password"
                    sx={{
                        width: "100%",
                    }}
                    value={confirmPassword}
                />
            </>


    }


    return (
        <Dialog
            open={props.open}
            onClose={() => {
                props.setDialogOpen(false);
                props.setContent(undefined);
            }}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100vw', // Adjust the width as needed
                    maxWidth: "20rem",
                    margin: '0 auto',
                    textAlign: 'center',
                },
            }}
        >

            <DialogTitle>

                <div className="dialog-title">
                    Update {props.dialogTitle}
                </div>
            </DialogTitle>

            <DialogContent
                sx={{
                    height: "100%"
                }}
            >
                {selector[props.dialogType]}

                <Button
                    disabled={props.dialogType == 'password' && !passwordSubmitActive}
                    onClick={() => handleSubmit(props.dialogType, formValue)}
                    className="login-button"
                    type="submit"
                    sx={{
                        width: "100%",
                        height: "5vh",
                        backgroundColor: "black",
                        marginTop: 2
                    }}
                >
                    <h3
                        className="login-text"
                        style={{
                            color: "white",
                            fontSize: "2vw"
                        }}
                    >Submit</h3>
                </Button>
            </DialogContent>


        </Dialog>
    )


}