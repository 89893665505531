import { Grid } from '@mui/material'
import React from 'react'
import OptionBox from './OptionBox'
import HandshakeIcon from '@mui/icons-material/Handshake';
import ArticleIcon from '@mui/icons-material/Article';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import './typeSelection.css'
import { useMediaQuery } from 'react-responsive';




export default function SelectionScreen({ setPage }) {

    function select(setPage, number) {
        setPage(number)
        console.log(number)
    }

    const iconStyling = { fontSize: 150, color: "white" }
    const typeStyling = { fontSize: '1.4rem', color: '#313131', fontWeight: 'bold' }

    const termsAndConditions = {
        escrowOnly: {
            title: "Terms and Conditions for use of Escrow Service",
            body:
                `
                This service allows users to place digital goods and money in custody with Handshake until both parties are satisfied, at which point the transaction will be completed.
                 Handshake acts as a platform to enable a transaction and accepts no responsibility for disputes.       
                `
        }
    }

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });


    return (
        <>

            <div>

                <Grid
                    className='option-container'
                    container
                    spacing={0}
                    direction={isTabletOrMobile ? "column" : "row"}
                    alignItems="center"
                    justifyContent="space-around"
                //style={{ minHeight: '80vh' }}
                >

                    <OptionBox
                        onClick={() => select(setPage, 1)}
                        icon={<CurrencyExchangeIcon sx={iconStyling} />}
                        title={
                            <div className='centered-text'>
                                Escrow Only
                            </div>
                        }
                        termsAndConditions={termsAndConditions.escrowOnly}
                    />

                    <OptionBox
                        onClick={() => select(setPage, 2)}
                        icon={<HandshakeIcon sx={iconStyling} />}
                        title={
                            <div className='centered-text'>
                                Escrow & Contract
                            </div>
                        }
                        termsAndConditions={termsAndConditions.escrowOnly}
                    />

                    <OptionBox
                        onClick={() => select(setPage, 3)}
                        icon={<ArticleIcon sx={iconStyling} />}
                        title={
                            <div className='centered-text'>
                                Contract Only
                            </div>
                        }
                        termsAndConditions={termsAndConditions.escrowOnly}
                    />

                </Grid>
            </div>
        </>
    )
}

