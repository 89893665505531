import React from "react";
import { Dialog, Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ClaimFunds(props) {

    const navigate = useNavigate()

    const [complete, setComplete] = React.useState();

    const [paymentDetails, setPaymentDetails] = React.useState();

    const server = process.env.REACT_APP_SERVER_ADDRESS


    React.useEffect(() => {
        setComplete(props.dealData.sellerComplete && props.dealData.buyerComplete)
    }, [props.dealData])


    const upfront = props.dealData.upfrontPaid
    const escrow = props.dealData.escrowPaid

    const upfrontClaimed = props.dealData.upfrontAccepted
    const escrowClaimed = props.dealData.escrowAccepted



    React.useEffect(() => {
        fetch(`${server}/user/paymentdetails`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('user_id')}`
            },
            body: JSON.stringify({
                "userId": sessionStorage.getItem('user_id'),
            })
        }
        )
            .then(resp => {
                if (resp.ok) {
                    return resp.json()
                }
                else {
                    throw new Error(`HTTP error! status: ${resp.status}`);
                }
            })
            .then(jsondata => {
                setPaymentDetails(JSON.parse(jsondata.data).status)
            }
            )
            .catch((error) => {
                console.error('Fetch error:', error.message);
            })
    }, [])

    if (paymentDetails != "complete" || !paymentDetails) {
        return (
            <Dialog
                open={props.open}
                onClose={() => props.setOpen(false)}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "500px",  // Set your width here
                        },
                    },
                }}
            >
                <Grid
                    container
                    justifyContent={"center"}
                    sx={{
                        marginY: "2rem"
                    }}
                >
                    {
                        paymentDetails == "due"

                            ?

                            <>
                                <div className="payment-details-text">Further details needed</div>
                                <div className="payment-details-subtext">Please ensure your personal details, payment information and ID are provided</div>
                            </>
                            :
                            <>
                                <div className="payment-details-text">Details undergoing verification</div>
                                <div className="payment-details-subtext">Details are being verified with Trustap, please check back later </div>
                            </>

                    }


                    <Button
                        className="claim-button"
                        sx={{
                            ...props.buttonSx,
                            backgroundColor: "black",
                        }}
                        onClick={event => window.location.href = (`https://app.stage.trustap.com/profile/payout/personal`)}
                    >
                        <div className="button-text">Redirect to Trustap</div>
                    </Button>
                </Grid>
            </Dialog>
        )
    }



    return (
        <Dialog
            open={props.open}
            onClose={() => props.setOpen(false)}
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "500px",  // Set your width here
                    },
                },
            }}
        >

            {
                props.dealData.paymentType == "upfront"
                    ?
                    <Grid
                        container
                        direction={"column"}
                        justifyContent={"space-between"}
                        sx={{
                            height: "40vh",
                            padding: "2rem"
                        }}
                    >
                        <Button
                            className="claim-button"
                            sx={{
                                ...props.buttonSx,
                                backgroundColor: !upfront || upfrontClaimed ? "lightgrey" : "black",
                            }}
                            disabled={(!upfront) || upfrontClaimed}
                        >
                            <div className="button-text">{upfrontClaimed ? "Deposit Claimed" : "Claim Deposit"}</div>
                        </Button>

                        <Button
                            className="claim-button"
                            sx={{
                                ...props.buttonSx,
                                backgroundColor: !(escrow && complete) || escrowClaimed ? "lightgrey" : "black",


                            }}
                            disabled={!(escrow && complete) || escrowClaimed}
                        >
                            <div className="button-text">{escrowClaimed ? "Escrow Claimed" : "Claim Escrow"}</div>
                        </Button>

                        <Button
                            className="claim-button"
                            sx={{
                                ...props.buttonSx,
                                backgroundColor: !(upfront && escrow && complete) || escrowClaimed || upfrontClaimed ? "lightgrey" : "black",

                            }}
                            disabled={!(upfront && escrow && complete) || escrowClaimed || upfrontClaimed}
                        >
                            <div className="button-text">Claim Deposit & Escrow</div>
                        </Button>
                    </Grid>
                    :
                    <Grid
                        container
                        direction={"column"}
                        justifyContent={"space-between"}
                        sx={{
                            height: "40vh",
                            padding: "2rem"
                        }}
                    >
                        {paymentDetails}

                        <Button
                            className="claim-button"
                            sx={{
                                ...props.buttonSx,
                                backgroundColor: "lightgrey"
                            }}
                            disabled
                        >
                            <div className="button-text">No deposit made</div>
                        </Button>

                        <Button
                            className="claim-button"
                            sx={{
                                ...props.buttonSx,
                                backgroundColor: !(escrow && complete) || escrowClaimed ? "lightgrey" : "black"

                            }}
                            disabled={!escrow || escrowClaimed}
                        >
                            {escrowClaimed ?
                                <div className="button-text">Escrow Claimed</div>
                                :
                                <div className="button-text">Claim Escrow</div>

                            }
                        </Button>

                        <Button
                            className="claim-button"
                            sx={{
                                ...props.buttonSx,
                                backgroundColor: "lightgrey"
                            }}
                            disabled
                        >
                            <div className="button-text">Claim Deposit & Escrow</div>
                        </Button>
                    </Grid>
            }


        </Dialog >
    )
}