import React from "react";
import { Divider, Stack } from "@mui/material";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from "react-router-dom";


export default function LineItem({ i }) {

    const navigate = useNavigate();

    const iconSx = { marginLeft: "1rem", marginTop: "0.3rem", marginBottom: "0.3rem" }

    const IconPicker = {
        'items': <AttachFileIcon sx={iconSx} />,
        'invitation': <InsertDriveFileIcon sx={iconSx} />,
        'revision': <FileCopyIcon sx={iconSx} />,
        'accept': <CheckCircleIcon sx={iconSx} />,
    }

    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={3}
                sx={{
                    height: "3rem",
                    "&:hover": {
                        backgroundColor: "#f5f5f5",
                        cursor: "pointer"
                    },
                }}
                onClick={() => navigate(i.redirect)}

            >
                <Stack direction={"row"} alignItems="center" spacing={3} justifyContent={"flex-start"}>
                    <div>{IconPicker[i.type]}</div>
                    <div>{i.text}</div>
                </Stack>
                <div>{(i.datetime)}</div>
            </Stack>
            <Divider />
        </>
    )
}

