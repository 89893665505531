import React from "react"
import { Box, FormControl, RadioGroup, Radio, FormControlLabel, TextField, Select, MenuItem, Divider } from "@mui/material"
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";

import { useMediaQuery } from "react-responsive";

import { Stack } from "@mui/material";

import './escrow.css'

import Stepper from "../Stepper";


export default function MoneyConfig(props) {


    const [itemCount, setItemCount] = React.useState(0);
    const [escrowRounded, setEscrowRounded] = React.useState(false)
    const [cost, setCost] = React.useState(0);
    //const [depositIncluded, setDepositIncluded] = React.useState(props.formData.de);
    const [escrow, setEscrow] = React.useState();
    const [sellerAmount, setSellerAmount] = React.useState()
    const [buyerAmount, setBuyerAmount] = React.useState()

    React.useEffect(() => {
        const existing = props.formData.items
        console.log(existing)
        console.log(cost)

        const sumOfItems = existing.reduce(
            (accumulator, currentItem) => accumulator + (currentItem.quantity * currentItem.unitCost),
            0
        );

        setCost(sumOfItems)

    }, [props.formData.items])



    const nextPage = () => {
        props.setEscrowStep(currentStep => currentStep + 1)
    }

    const prevPage = () => {
        props.setEscrowStep(currentStep => currentStep - 1)
    }

    const isMobile = useMediaQuery({
        query: '(max-device-width: 900px)',
    });


    const calculateTotal = (upfrontAmount, itemCost) => {

        const upfront = upfrontAmount ? upfrontAmount : 0

        if (props.formData.depositIncluded) {
            return parseFloat(upfront) + parseFloat(itemCost)
        }
        else {
            if (!itemCost) {
                return parseFloat(upfront)
            }
            return parseFloat(upfront) + parseFloat(itemCost)
        }
    }


    const calculateEscrow = (upfrontAmount, itemCost) => {

        if (props.formData.depositIncluded) {
            const total = parseFloat(itemCost) - parseFloat(upfrontAmount);
            setEscrow(total > 0 ? Math.max(total, 5) : 0)

            console.log("TOTAL", total)
            setEscrowRounded((total < 5 && total >= 0))
            console.log("CONDITIONS", (total <= 5 && total >= 0))

            return total > 0 ? Math.max(total, 5) : 0;
        } else {
            const total = parseFloat(itemCost);
            setEscrow(total > 0 ? Math.max(total, 5) : 0)

            setEscrowRounded(total < 5 && total >= 0)

            return total > 0 ? Math.max(total, 5) : 0;
        }
    };


    React.useEffect(() => {
        const total = calculateEscrow(props.formData.upfrontPayment, cost);
        setEscrow(total);
        props.setFormData({ ...props.formData, escrowPayment: total });
    }, [props.formData.upfrontPayment, cost, props.formData.depositIncluded, props.formData.paymentType]);


    const handleChange = (val) => {
        props.setFormData({ ...props.formData, depositIncluded: val });
        //setDepositIncluded(val);
    }

    const toggleUpfront = (val) => {
        if (val == "oncompletion") {
            props.setFormData({ ...props.formData, paymentType: val, upfrontPayment: 0 })
        }
        else {
            props.setFormData({ ...props.formData, paymentType: val, upfrontPayment: 5 })

        }
    }



    function feeAdded(amount) {
        return (amount * 1.03) + 0.8
    }

    function feeTaken(amount) {
        return (amount - 0.8) / 1.03

    }

    const calculateBuyer = () => {
        if (props.formData.paymentType != "oncompletion") {
            const calculatedUpfront = feeAdded(props.formData.upfrontPayment)
            const calculatedEscrow = props.formData.escrowPayment > 0 ? feeAdded(escrow) : 0
            return (calculatedUpfront + calculatedEscrow).toFixed(2)
        }
        else {
            return feeAdded(escrow).toFixed(2)
        }
    }

    const calculateSeller = () => {
        if (props.formData.paymentType != "oncompletion") {
            const calculatedUpfront = feeTaken(props.formData.upfrontPayment)
            const calculatedEscrow = props.formData.escrowPayment > 0 ? feeTaken(escrow) : 0
            return (calculatedUpfront + calculatedEscrow).toFixed(2)
        }
        else {
            return feeTaken(escrow).toFixed(2)
        }
    }


    React.useEffect(() => {

        const subtotal = calculateTotal(props.formData.upfrontPayment, escrow)
        console.log(props.formData.buyerPaysFees)

        if (props.formData.buyerPaysFees == true) {
            setSellerAmount(subtotal)
            setBuyerAmount(calculateBuyer())
            console.log("SELLER", subtotal, "BUYER", calculateBuyer())

        }
        else {
            setSellerAmount(calculateSeller())
            setBuyerAmount(subtotal)
            console.log("SELLER", calculateSeller(), "BUYER", subtotal)
        }

    }, [props.formData.buyerPaysFees, escrow, props.formData.upfrontPayment])


    return (
        <>

            <Stepper direction={'backwards'} active={true} clickFunction={prevPage} />

            <button onClick={() => console.log(props.formData)}>Click for form</button>

            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
            >

                <Stack
                    direction="column"
                    sx={{
                        marginTop: isMobile ? 10 : 5,
                        width: "90%",
                    }}
                >
                    <div className="title-text">Financial Configuration</div>

                </Stack>



                <Grid item xs={3} sx={{ width: "90%" }}>
                    <h4>Is all or partial payment being made upfront?</h4>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        onChange={(e) => toggleUpfront(e.target.value)}
                        value={props.formData.paymentType}
                        row
                    >
                        <FormControlLabel value="oncompletion" control={<Radio />} label="No" />
                        <FormControlLabel value="upfront" control={<Radio />} label="Yes" />
                    </RadioGroup>
                </Grid>

                <Grid
                    item
                    xs={3}
                    sx={{ width: "90%", my: 1 }}
                    style={props.formData.paymentType == "upfront" ? { 'display': 'block' } : { 'display': 'none' }}
                >
                    <TextField
                        id="standard-basic"
                        type="number"
                        variant="standard"
                        inputProps={{ min: 5 }}

                        label={
                            props.formData.paymentType == "upfront" && props.formData.upfrontPayment < 5
                                ?
                                <div style={{ color: "red" }}>Value must be more than £5</div>
                                :
                                <div>Amount to be paid upfront (£)</div>


                        }
                        value={props.formData.upfrontPayment}
                        onChange={(e) => props.setFormData({ ...props.formData, upfrontPayment: e.target.value })}
                    />
                </Grid>


                <Grid item xs={3} sx={{ width: "90%", my: 1 }}>

                    <div className="summary-row"> <span className="summary-header">Item Value</span> <span>£{cost}</span> </div>
                    <Divider sx={{ background: "grey", width: "90%", marginTop: "0.5rem" }} />

                    {
                        props.formData.paymentType == "upfront"
                            ?
                            <div>
                                <Stack
                                    className="summary-row"
                                    direction={"row"}
                                    alignContent={"space-between"}
                                >
                                    <span className="summary-header">Upfront Cost</span>

                                    <FormControl variant="standard" sx={{ minWidth: 300 }}>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            label="Age"
                                            value={props.formData.depositIncluded ? true : false}
                                            onChange={(e) => (handleChange(e.target.value))}
                                            sx={{
                                                padding: 0,
                                            }}
                                        >
                                            <MenuItem value={true}><span className="summary-subheader">Upfront pays towards item value</span></MenuItem>
                                            <MenuItem value={false}><span className="summary-subheader">Upfront is in addition to item value</span></MenuItem>
                                        </Select>
                                    </FormControl>
                                    <span>£{props.formData.upfrontPayment}</span>

                                </Stack>

                                <Divider sx={{ background: "grey", width: "90%", marginTop: "0.5rem" }} />

                            </div>
                            :
                            <></>
                    }

                    {
                        cost ? <div className="summary-row"> <span className="summary-header">Escrow Cost</span> <span>£{escrow}</span> </div> : <></>
                    }

                    <div className="warning-row" style={{ display: escrowRounded && escrow != 0 ? "block" : "none" }}> Rounded Up (Minimum Payment)</div>
                    <Divider sx={{ background: "grey", width: "90%", marginTop: "0.5rem" }} />

                    <div className="summary-row"> <span className="summary-header">Subtotal</span> <span>£{calculateTotal(props.formData.upfrontPayment, escrow)}</span> </div>
                    <Divider sx={{ background: "grey", width: "90%", marginTop: "0.5rem" }} />

                </Grid>

                <Grid item xs={3} sx={{ width: "90%", my: 1 }}>
                    <h4>Who is paying the fees?</h4>
                    <p>Fees are 3% + £0.80.</p>

                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        onChange={(e) => props.setFormData({ ...props.formData, buyerPaysFees: e.target.value === "true" ? true : false })}
                        value={props.formData.buyerPaysFees}
                    >
                        <FormControlLabel value={true} control={<Radio />} label="Buyer" />
                        <FormControlLabel value={false} control={<Radio />} label="Seller" />
                    </RadioGroup>
                </Grid>

                <Grid item xs={3} sx={{ width: "90%", my: 1 }}>
                    <div className="summary-row"> <span className="summary-header">Totals</span> </div>

                    <div className="summary-row"> <span className="summary-header">Buyer Paying</span> <span>£{buyerAmount}</span> </div>
                    <Divider sx={{ background: "grey", width: "90%", marginTop: "0.5rem" }} />

                    <div className="summary-row"> <span className="summary-header">Seller Receiving</span> <span>£{sellerAmount}</span> </div>
                    <Divider sx={{ background: "grey", width: "90%", marginTop: "0.5rem" }} />

                </Grid>






            </Grid >
            <Stepper direction={'forwards'} active={!(props.formData.paymentType == "upfront" && props.formData.upfrontPayment < 5)} clickFunction={nextPage} />

        </>

    )
}
