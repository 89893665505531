import React from "react";
import { Stack, Grid, Box } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './splash.css'

export default function SimpleSplash(props) {

    function changeBackground(e) {
        e.target.style.color = 'red';
    }

    function defaultBackground(e) {
        e.target.style.color = 'black';
    }

    return (
        <div
            style={{
                display: 'flex', // Set the parent container to flex
                alignItems: 'center', // Vertically center children
                justifyContent: 'center', // Horizontally center children
                minHeight: '100vh', // Make sure the container covers at least the viewport height
            }}
        >
            <Grid
                className="splash-text"
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                    minHeight: '100vh',
                    width: '100vw',
                    display: 'flex', // Add this line
                    alignItems: 'center', // Add this line
                    justifyContent: 'center', // Add this line
                }}
            >

                <div
                    className="kashd-splash-text"
                >
                    KASHD
                </div>

                <Stack
                    direction={'column'}
                    justifyContent="center"
                    alignItems="center"
                    spacing={5}
                >
                    <div className="kashd-subheader">
                        Escrow for freelancers
                    </div>


                </Stack>

            </Grid>


        </div>
    )
}
