import React from "react";
import { Box, Grid, Stack, Paper } from "@mui/material";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


import './dealprofile.css'

export default function ItemDetailsTable(props) {

    const [expandedColumns, setExpandedColumns] = React.useState([]);
    const [open, setOpen] = React.useState(false);

    const handleRowClick = (key) => {
        console.log(key)
        console.log(expandedColumns)

        if (expandedColumns.includes(key)) {
            const newArr = expandedColumns.filter((item) => {
                return item !== key
            })
            setExpandedColumns(newArr)
        }
        else {
            setExpandedColumns([...expandedColumns, key])
        }
    }

    const getQuant = (item) => {
        let count = 0;

        if (!item.files) {
            return count
        }

        item.files.forEach((file) => {
            count += file.quantity;
        });

        return count
    };


    return (

        <>

            <Box
                sx={{
                    marginY: 2,
                    width: "100%"
                }}
            >
                <Paper elevation={3}>

                    <Stack
                        className="box-title"
                        sx={{
                            background: "black"
                        }}
                        direction={"row"}
                        justifyContent={"space-between"}
                    >
                        <div className="profile-header">Items And Files</div>
                        <Box
                            sx={{ cursor: "pointer" }}
                        >
                            {props.seller && props.accepted && !props.dealData.cancelled
                                ?
                                <div className="profile-header" onClick={() => props.setItemUploadOpen(true)}>Add Items</div>
                                :
                                ''
                            }
                        </Box>
                    </Stack>

                    <Stack
                        direction={"column"}
                        sx={{
                            marginY: 1,
                            padding: 1.5
                        }}
                    >

                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table stickyHeader aria-label="sticky table">

                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: "25%" }}>
                                            <div className="table-header">
                                                Item
                                            </div>
                                        </TableCell>
                                        <TableCell sx={{ width: "25%" }}>
                                            <div className="table-header">
                                                Assets Expected
                                            </div>
                                        </TableCell>
                                        <TableCell sx={{ width: "25%" }}>
                                            <div className="table-header">
                                                Uploaded
                                            </div>
                                        </TableCell>
                                        <TableCell sx={{ width: "25%" }}>
                                            <div className="table-header">
                                                Unit Cost
                                            </div>
                                        </TableCell>

                                    </TableRow>
                                </TableHead>

                                {props.dealData.items.map((item, key) => (
                                    <>
                                        <TableRow
                                            sx={{
                                                background: "#f5f5f5",
                                                cursor: "pointer"

                                            }}
                                            onClick={() => handleRowClick(key)}
                                        >
                                            <TableCell>
                                                {item.description}
                                            </TableCell>
                                            <TableCell>
                                                {item.quantity}
                                            </TableCell>
                                            <TableCell>
                                                {getQuant(item)}
                                            </TableCell>
                                            <TableCell>
                                                {item.unitCost}
                                            </TableCell>
                                        </TableRow>




                                        {/*Hidden rows detailing files uploaded for each item*/}
                                        {expandedColumns.includes(key) ?
                                            <>
                                                <TableHead>
                                                    <TableRow
                                                        sx={{ width: "100%" }}
                                                    >
                                                        <TableCell colSpan={1}>
                                                            <div className="table-header">
                                                                File Name
                                                            </div>
                                                        </TableCell>

                                                        <TableCell colSpan={1}>
                                                            <div className="table-header">
                                                                Contains Quantity
                                                            </div>
                                                        </TableCell>

                                                        <TableCell colSpan={1}>
                                                            <div className="table-header">
                                                                Date Uploaded
                                                            </div>
                                                        </TableCell>

                                                        <TableCell colSpan={1}>
                                                            <div className="table-header">
                                                                File Type
                                                            </div>
                                                        </TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {item.files.map((file, key) => (
                                                        <>
                                                            <TableRow>
                                                                <TableCell>
                                                                    {file.filename}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {file.quantity}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {file.uploaded}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {file.filetype}
                                                                </TableCell>
                                                            </TableRow>

                                                        </>
                                                    ))
                                                    }

                                                </TableBody>
                                            </>
                                            :
                                            <></>
                                        }




                                    </>
                                ))
                                }


                            </Table>
                        </TableContainer>

                    </Stack>
                </Paper>

            </Box>

        </>

    )

}

