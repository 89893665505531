import React, { useEffect } from "react";
import SplashBaseTwo from "../components/Splash/SplashBaseTwo";
import LoginDialog from "../components/Splash/LoginDialog";
import RegisterDialog from "../components/Splash/RegisterDialog";
import ForgotPasswordDialog from "../components/Splash/ForgotPasswordDialog";


export default function TestSplash() {

    const server = process.env.REACT_APP_SERVER_ADDRESS


    const [loginOpen, setLoginOpen] = React.useState(false);
    const [registerOpen, setRegisterOpen] = React.useState(false);
    const [passwordOpen, setPasswordOpen] = React.useState(false);

    const [existingCredentials, setExistingCredentials] = React.useState();


    useEffect(() => {
        fetch('https://api.ipify.org/?format=json')
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.status)
                }
                else return response.json()
            })
            .then(data => console.log(data.ip))
            .catch((error) => {
                console.log('error: ' + error);
            });
    }, [])


    useEffect(() => {
        fetch(`${server}/user/existingcredentials`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
            .then(resp => resp.json())
            .then(jsondata => setExistingCredentials(jsondata.data))

    }, [])

    return (
        <>
            <SplashBaseTwo
                setLoginOpen={setLoginOpen}
                setRegisterOpen={setRegisterOpen}
                setPasswordOpen={setPasswordOpen}
            />
            <LoginDialog
                loginOpen={loginOpen}
                setLoginOpen={setLoginOpen}
            />

            <RegisterDialog
                existingCredentials={existingCredentials}
                registerOpen={registerOpen}
                setRegisterOpen={setRegisterOpen}
            />

            <ForgotPasswordDialog
                passwordOpen={passwordOpen}
                setPasswordOpen={setPasswordOpen}
            />

        </>
    )
}
