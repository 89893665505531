import React from "react"
import Grid from "@mui/material/Grid";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment'
import dayjs from "dayjs";
import { Stack } from "@mui/material";
import { FormGroup } from "@mui/material";
import { Checkbox } from "@mui/material";

import Stepper from './Stepper'



import TextField from "@mui/material/TextField";


export default function UserConfig(props) {


    const difference = props.differences

    const [instUser, setInstUser] = React.useState(props.formData.inst ? "yes" : "no");

    const dateToString = (date) => {
        return date ? moment(date).format('DD/MM/YYYY') : ''
    };

    const handleChange = (date) => {
        props.setFormData({
            ...props.formData,
            dueDate: date ? dateToString(date.toDate()) : ''
        });
    };

    var customParseFormat = require('dayjs/plugin/customParseFormat')
    dayjs.extend(customParseFormat)

    const prevPage = () => {
        console.log('yeah')
    }

    const nextPage = () => {
        props.setEscrowStep(1)
    }

    return (
        <>

            <Stepper direction={"backwards"} active={true} clickFunction={prevPage} />

            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
            >

                <Grid item xs={3} sx={{ width: "90%", my: 1 }}>
                    <h4>Reveal the following contact details to the other party</h4>
                    {
                        props.formData.authorRole == "buyer"
                            ?
                            <FormGroup>
                                <FormControlLabel control={<Checkbox disabled checked={true} onClick={() => props.setFormData({ ...props.formData, buyerUsername: !props.formData.buyerUsername })} />} label="Username" />
                                <FormControlLabel control={<Checkbox checked={props.formData.buyerEmail} onClick={() => props.setFormData({ ...props.formData, buyerEmail: !props.formData.buyerEmail })} />} label="Email Address" />
                                <FormControlLabel control={<Checkbox checked={props.formData.buyerName} onClick={() => props.setFormData({ ...props.formData, buyerName: !props.formData.buyerName })} />} label="Name" />
                                <FormControlLabel control={<Checkbox checked={props.formData.buyerPhone} onClick={() => props.setFormData({ ...props.formData, buyerPhone: !props.formData.buyerPhone })} />} label="Phone Number" />
                            </FormGroup>
                            :
                            <FormGroup>
                                <FormControlLabel control={<Checkbox disabled checked={true} onClick={() => props.setFormData({ ...props.formData, sellerUsername: !props.formData.sellerUsername })} />} label="Username" />
                                <FormControlLabel control={<Checkbox checked={props.formData.sellerEmail} onClick={() => props.setFormData({ ...props.formData, sellerEmail: !props.formData.sellerEmail })} />} label="Email Address" />
                                <FormControlLabel control={<Checkbox checked={props.formData.sellerName} onClick={() => props.setFormData({ ...props.formData, sellerName: !props.formData.sellerName })} />} label="Name" />
                                <FormControlLabel control={<Checkbox checked={props.formData.sellerPhone} onClick={() => props.setFormData({ ...props.formData, sellerPhone: !props.formData.sellerPhone })} />} label="Phone Number" />
                            </FormGroup>
                    }
                </Grid>

                <Grid item xs={3} sx={{ width: "90%", my: 1 }}>
                    <h4>Title of transaction</h4>
                    {
                        difference["title"]
                            ?
                            <TextField
                                error
                                disabled
                                id="outlined-password-input"
                                label="Changed From"
                                sx={{ width: "100%", my: 2 }}
                                value={difference["title"].compare}
                            />
                            :
                            <></>
                    }
                    <TextField
                        id="standard-basic" variant="standard"
                        sx={{
                            width: "100%"
                        }}
                        value={props.formData.title}
                        onChange={(e) => (
                            props.setFormData({ ...props.formData, title: e.target.value }),
                            props.setChanged(true)
                        )}
                    />
                </Grid>

                <Grid item xs={3} sx={{ width: "90%", my: 1 }}>
                    <h4>Other Party Identification</h4>
                    {

                        props.formData.authorRole == 'buyer'
                            ?
                            <Stack spacing={2} width={"100%"} >
                                <TextField
                                    label="Username"
                                    disabled
                                    id="standard-basic" variant="standard"
                                    sx={{
                                        width: "100%",
                                        display: props.formData.sellerUsername ? 'inline' : 'none'
                                    }}
                                    value={props.seller.username}
                                />
                                <TextField
                                    label="Email Address"
                                    disabled
                                    id="standard-basic" variant="standard"
                                    sx={{
                                        width: "100%",
                                        display: props.formData.sellerEmail ? 'inline' : 'none'
                                    }}
                                    value={props.seller.email}
                                />
                                <TextField
                                    label="Name"
                                    disabled
                                    id="standard-basic" variant="standard"
                                    sx={{
                                        width: "100%",
                                        display: props.formData.sellerName ? 'inline' : 'none'
                                    }}
                                    value={props.seller.fullname}
                                />
                                <TextField
                                    label="Phone Number"
                                    disabled
                                    id="standard-basic" variant="standard"
                                    sx={{
                                        width: "100%",
                                        display: props.formData.sellerPhone ? 'inline' : 'none'
                                    }}
                                    value={props.seller.phone}
                                />
                            </Stack>

                            :
                            <Stack spacing={2} width={"100%"} >
                                <TextField
                                    label="Username"
                                    disabled
                                    id="standard-basic" variant="standard"
                                    sx={{
                                        width: "100%",
                                        display: props.formData.buyerUsername ? 'inline' : 'none'
                                    }}
                                    value={props.buyer.username}
                                />
                                <TextField
                                    label="Email Address"
                                    disabled
                                    id="standard-basic" variant="standard"
                                    sx={{
                                        width: "100%",
                                        display: props.formData.buyerEmail ? 'inline' : 'none'
                                    }}
                                    value={props.buyer.email}
                                />
                                <TextField
                                    label="Name"
                                    disabled
                                    id="standard-basic" variant="standard"
                                    sx={{
                                        width: "100%",
                                        display: props.formData.buyerName ? 'inline' : 'none'
                                    }}
                                    value={props.buyer.fullname}
                                />
                                <TextField
                                    label="Phone Number"
                                    disabled
                                    id="standard-basic" variant="standard"
                                    sx={{
                                        width: "100%",
                                        display: props.formData.buyerPhone ? 'inline' : 'none'
                                    }}
                                    value={props.buyer.phone}
                                />
                            </Stack>

                    }
                </Grid>

                <Grid item xs={3} sx={{ width: "90%", my: 1, display: props.formData.otherPartyInst ? 'inline' : 'none' }}>
                    <h4>Business or Institution of Other Party</h4>
                    <TextField
                        disabled
                        id="standard-basic" variant="standard"
                        sx={{ width: "100%" }}
                        value={props.formData.otherPartyInst}
                        onChange={(e) => (
                            props.setFormData({ ...props.formData, otherPartyInst: e.target.value }),
                            props.setChanged(true)
                        )}
                    />
                </Grid>

                <Grid item xs={3} sx={{ width: "90%", my: 1 }}>
                    <h4>Are you representing a business or institution?</h4>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        onChange={(e) => (
                            setInstUser(e.target.value),
                            props.setChanged(true)
                        )}
                        value={instUser}
                    >
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    </RadioGroup>
                </Grid>

                <Grid item xs={3} sx={{ width: "90%", my: 1 }} style={instUser == "yes" ? { 'display': 'block' } : { 'display': 'none' }}>


                    <TextField

                        id="standard-basic" variant="standard"
                        sx={{
                            width: "100%"
                        }}
                        label="Business or institution name"
                        value={props.formData.inst}
                        onChange={(e) => (
                            props.setFormData({ ...props.formData, inst: e.target.value }),
                            props.setChanged(true)
                        )}
                    />
                </Grid>




                <Grid item xs={3} sx={{ width: "90%", my: 1 }}>
                    <h4>Are YOU the seller or the buyer in this transaction?</h4>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        onChange={(e) => (
                            props.setFormData({ ...props.formData, authorRole: e.target.value }),
                            props.setChanged(true)
                        )}
                        value={props.formData.authorRole}
                    >
                        <FormControlLabel value="seller" control={<Radio disabled />} label="Seller" />
                        <FormControlLabel value="buyer" control={<Radio disabled />} label="Buyer" />
                    </RadioGroup>
                </Grid>




                <Grid item xs={3} sx={{ width: "90%", my: 1 }}>
                    <h4>What is the due date?</h4>
                    <Stack spacing={2} sx={{ width: 400 }}>
                        {
                            difference["dueDate"]
                                ?
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en'}>
                                    <DesktopDatePicker
                                        error
                                        disabled
                                        label="Changed From"
                                        inputFormat="DD/MM/YYYY"
                                        format="DD/MM/YYYY"
                                        value={dayjs(difference["dueDate"].compare)}
                                    //onChange={(e) => props.setFormData({ ...props.formData, dueDate: dateToString(e.$d) })}
                                    />
                                </LocalizationProvider>
                                :
                                <></>
                        }
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en'}>
                            <DesktopDatePicker
                                label="Currently"
                                inputFormat="DD/MM/YYYY"
                                format="DD/MM/YYYY"
                                value={props.formData.dueDate ? dayjs(props.formData.dueDate, "DD/MM/YYYY") : null}
                                onChange={(e) => (
                                    props.setFormData({ ...props.formData, dueDate: dateToString(e.$d) }),
                                    props.setChanged(true)
                                )}
                            />
                        </LocalizationProvider>
                    </Stack>
                </Grid>



            </Grid >
            <Stepper direction={"forwards"} active={true} clickFunction={nextPage} />
        </>
    )

}


