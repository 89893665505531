import React from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import "./css/Confirm.css"

export default function Confirm() {

    const server = process.env.REACT_APP_SERVER_ADDRESS

    const [statusCode, setStatus] = React.useState();
    const [redirect, setRedirect] = React.useState('/');
    const [userIp, setUserIp] = React.useState();

    let navigate = useNavigate();

    const params = useParams();

    useEffect(() => {
        fetch('https://api.ipify.org/?format=json')
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.status)
                }
                else return response.json()
            })
            .then(data => setUserIp(data.ip))
            .catch((error) => {
                console.log('error: ' + error);
                setUserIp("failure")
            });
    }, [])


    const handleSubmit = async (event) => {

        fetch(`${server}/user/confirm`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: params.access_token,
                ip: userIp
            })
        })
            .then(response => {
                if (!response.ok) {
                    setStatus(response.status);
                    console.log(response.status)
                    throw new Error('Network response was not ok');
                }
                setStatus(200)
            })

            .catch(error => {
                console.error('An error occurred', error);
            });
    };


    const redirectTimer = () => {
        setTimeout(() => {
            navigate(redirect)
        }, 3000)
    }

    useEffect(() => {
        if (!userIp || userIp == "failed") {
            return undefined
        }
        else {
            handleSubmit();
        }
    }, [userIp]);

    useEffect(() => {
        redirectTimer();
    }, []);


    const successHeader = "Registration Successful"
    const successSubtitle = "You will be redirected shortly"

    const failureHeader = "Registration Unsuccessful"
    const failureSubtitle = "An error was encountered"
    const failureSubSubtitle = "You will be redirected shortly"


    const notify =
        (

            <Grid
                container
                direction={"column"}
                alignContent={"center"}
                justifyContent={"center"}
                sx={{
                    width: "100%",
                    height: "100vh"
                }}
            >
                <div className='confirm-title'>{statusCode === 200 ? successHeader : failureHeader}</div>
                <div className='confirm-subtitle'>{statusCode === 200 ? successSubtitle : failureSubtitle}</div>

            </Grid>


        )


    return (
        <div>
            <Grid
                container
                direction={"column"}
                alignContent={"center"}
                justifyContent={"center"}
                sx={{
                    width: "100%",
                    height: "100vh"
                }}
            >
                <div className='confirm-title'>{statusCode === 200 ? successHeader : failureHeader}</div>
                <div className='confirm-subtitle'>{statusCode === 200 ? successSubtitle : failureSubtitle}</div>
                <div className='confirm-subtitle'>{statusCode === 200 ? <></> : failureSubSubtitle}</div>


            </Grid>
        </div>
    )

}