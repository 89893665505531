import React from "react";
import { Stack, Box } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import './profile.css'


export default function ItemStack({ setDialogOpen, setDialogType, setDialogTitle, setContent, title, type, content, editable, firstname, lastname }) {

    const clicked = () => {

        if (type == 'name') {
            setContent({
                'firstname': firstname,
                'lastname': lastname
            })
            setDialogOpen(true);
            setDialogType(type)
            setDialogTitle(title)
        }
        else {
            setDialogOpen(true);
            setDialogType(type)
            setDialogTitle(title)
            setContent(content)
        }
    }

    return (
        <Box>
            <Stack direction={"row"} spacing={2}>

                <Box className="profile-item-title">
                    {title}
                </Box>

                {
                    editable ?
                        <EditIcon
                            onClick={() => clicked()}
                            sx={{
                                marginLeft: 0,
                                "&:hover": {
                                    cursor: "pointer"
                                }
                            }}
                        />
                        :
                        <></>
                }

            </Stack>

            <Box
                sx={{
                    marginY: 2
                }}
            >
                {content}
            </Box>


        </Box>
    )
}