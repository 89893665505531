import React from "react";
import { Button, Dialog, Grid, Box } from "@mui/material";
import ClaimFunds from "./ClaimFunds";
import TrustapDialog from "./TrustapDialog";
import './dealprofile.css'

export default function SellerClaimButton(props) {

    const [open, setOpen] = React.useState();


    const buttonSx = {
        width: "100%",
        height: "10vh",
        '&:hover': {
            backgroundColor: 'black',
        },
    }


    return (
        <>

            <Box className="info-box">
                Funds will become available when both parties have completed
            </Box>

            <Button
                className="claim-button"
                onClick={() => setOpen(true)}
                sx={{
                    ...buttonSx,
                    marginBottom: 2,
                    background: "black"
                }}
            >
                <div className="big-button-text">Claim Funds</div>
            </Button>

            {props.dealData.trustapFull
                ?
                <ClaimFunds
                    buttonSx={buttonSx}
                    dealData={props.dealData}
                    open={open}
                    setOpen={setOpen}
                />
                :
                <TrustapDialog
                    buttonSx={buttonSx}
                    open={open}
                    setOpen={setOpen}
                />
            }


        </>
    )

}