import React from 'react'
import Confirm from './pages/Confirm';
import Initiate from './pages/Initiate';
import Protection from './Protection';
import Review from './pages/Review';
import { Profile } from './pages/Profile';
import Notifications from './pages/Notifications';
import DealProfile from './pages/DealProfile';
import TestSplash from './pages/TestSplash';
import TestDashboard from './pages/TestDashboard';
import PasswordReset from './pages/PasswordReset';
import TrustapIncoming from './pages/TrustapIncoming';
import Splash from './pages/Splash';
import SimpleSplash from './components/Splash/SimpleSplash';
import MultiDash from './pages/MultiDash';
import TrustapPayment from './pages/TrustapPayment';
import MobileDash from './pages/MobileDash';

import useMediaQuery from '@mui/material/useMediaQuery';

import {
    Routes,
    Route
} from "react-router-dom";

export default function Views() {
    /*
    Non-public roles are wrapped in a protection element
    This element has allowed roles passed in with the user checked against the before rendering (see pages/Protection.js)
    */

    const isSmallScreen = useMediaQuery('(max-width:500px)');

    return (
        <Routes>


            <Route path="/" element={
                isSmallScreen
                    ?
                    <Protection authenticated={<MobileDash />} allowedRoles={['user']} />
                    :
                    <Protection authenticated={<MultiDash />} allowedRoles={['user']} />
            } />



            <Route path="/confirm">
                <Route path=":access_token" element={<Confirm />} />
            </Route>

            <Route path="/confirmtest">
                <Route path=":access_token" element={<Confirm />} />
            </Route>

            <Route path="/passwordreset">
                <Route path=":reset_token" element={<PasswordReset />} />
            </Route>

            <Route path="/login" element={<TestSplash />} />

            <Route path="/profile" element={<Profile />} />

            <Route path="/dashboard" element={<MultiDash />} />

            <Route path="/initiate" element={<Initiate />} />

            <Route path="/notifications" element={<Notifications />} />

            <Route path="/splash" element={<SimpleSplash />} />

            <Route path="/transactionprofile">
                <Route path=":deal_id" element={<DealProfile />} />
            </Route>

            <Route path="/review">
                <Route path=":deal_id" element={<Review />} />
            </Route>

            <Route path="/testsplash" element={<TestSplash />} />

            <Route path="/trustap-login" element={<TrustapIncoming />} />

            <Route path="/trustap-payment" element={<TrustapPayment />} />

        </Routes>
    )
}