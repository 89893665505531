import React from "react"
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "react-responsive";

import './typeSelection.css'



export default function OptionBox(props) {

    const [open, setOpen] = React.useState(false);

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });



    return (

        <Grid
            className="child-grid"
            container
            direction="column"
            alignItems="center"
            justifyContent="space-around"
            sx={{
                width: isTabletOrMobile ? "100%" : "33%",
                height: isTabletOrMobile ? 'calc(33vh - 22px)' : 'calc(100vh - 65px)',
                margin: 0,
                background: "#ffffff",
                cursor: 'pointer'

            }}
            onClick={props.onClick}

        >
            <div
                className="option-text"
            >{props.title}</div>

        </Grid>
    )

}