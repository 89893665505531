import React from "react"
import { Box } from "@mui/material"
import Paper from '@mui/material/Paper';
import Grid from "@mui/material/Grid";


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from '@mui/icons-material/Cancel';

import TextField from "@mui/material/TextField";

import Stepper from "./Stepper";


export default function ItemConfig(props) {

    const currentItems = props.differences.items.current
    const compareItems = props.differences.items.compare

    const currentIds = Object.keys(currentItems)
    const compareIds = Object.keys(compareItems)

    const additions = currentIds.filter(item => !compareIds.includes(item)); //IDs of items added
    const removals = compareIds.filter(item => !currentIds.includes(item)); //IDs of items removed
    const remain = compareIds.filter(item => currentIds.includes(item)); //IDs of items that stayed accross iterations

    //console.log('current', currentItems)

    const additionArray = []
    const removalArray = []

    for (let i = 0; i < additions.length; i++) {
        additionArray.push({ ...currentItems[additions[i]], 'styling': 'green' })
    }

    for (let i = 0; i < removals.length; i++) {
        removalArray.push({ ...compareItems[removals[i]], 'styling': 'red' })
    }

    console.log(removalArray)


    const [itemCount, setItemCount] = React.useState(0)

    function addItem() {
        // Using a functional update for props.setFormData to ensure immutability
        props.setFormData(prevFormData => ({
            ...prevFormData,
            items: [...prevFormData.items, { 'description': null, 'quantity': null, 'unitCost': null }]
        }));

        // Correctly increment itemCount using a functional update
        setItemCount(prevItemCount => prevItemCount + 1);
    }


    function alterRow(index, field, data) {
        props.setFormData(prevFormData => {
            // Clone the items array from the previous state
            const updatedItems = prevFormData.items.map((item, idx) => {
                if (idx === index) {
                    return { ...item, [field]: data };
                }
                return item;
            });

            return { ...prevFormData, items: updatedItems };
        });
    }


    function removeRow(index) {
        props.setFormData(prevFormData => {
            // Use filter to create a new array excluding the item at the specified index
            const updatedItems = prevFormData.items.filter((_, idx) => idx !== index);
            return { ...prevFormData, items: updatedItems };
        });
    }


    const prevPage = () => {
        props.setEscrowStep(0)
    }

    const nextPage = () => {
        props.setEscrowStep(2)
    }


    return (

        <>
            <Stepper direction={"backwards"} active={true} clickFunction={prevPage} />

            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width="75%"><h4>Item Description</h4></TableCell>
                            <TableCell><h4>Quantity</h4></TableCell>
                            <TableCell><h4>Cost</h4></TableCell>
                            <TableCell><h4>Remove</h4></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {removalArray.map((entry, index) =>

                            <TableRow key={index} sx={{ color: entry.styling }}>
                                <TableCell
                                    width="75%"
                                    sx={{
                                        borderBottom: "none"
                                    }}
                                >
                                    <span style={{ color: "red", marginRight: "10px" }}>Item Removed</span>

                                    <TextField
                                        disabled
                                        id="standard-basic" variant="standard"
                                        sx={{
                                            width: "90%"
                                        }}
                                        value={entry.description}
                                    />
                                </TableCell>

                                <TableCell>
                                    <TextField
                                        disabled
                                        id="standard-basic" variant="standard"
                                        sx={{
                                            width: "90%"
                                        }}
                                        value={entry.quantity}
                                    />
                                </TableCell>
                                <TableCell>

                                    <TextField
                                        disabled
                                        id="standard-basic" variant="standard"
                                        sx={{
                                            width: "90%"
                                        }}
                                        value={entry.unitCost}
                                    />

                                </TableCell>

                            </TableRow>
                        )}


                        {props.formData.items.map((entry, index) =>

                            <TableRow key={index} >

                                <TableCell
                                    width="75%"
                                    sx={{
                                        borderBottom: "none"
                                    }}
                                >
                                    {additions.includes(JSON.stringify(entry.id)) ? <span style={{ color: "green", marginRight: "10px" }}>New Addition</span> : null}

                                    <TextField
                                        id="standard-basic" variant="standard"
                                        sx={{
                                            width: "90%"
                                        }}
                                        value={props.formData.items[index].description}
                                        onChange={(e) => {
                                            alterRow(index, 'description', e.target.value);
                                        }}
                                    />
                                    <Tooltip>
                                        {
                                            compareItems[entry.id]
                                                ?
                                                currentItems[entry.id]['description'] == compareItems[entry.id]['description']
                                                    ?
                                                    <></>
                                                    :
                                                    <span style={{ color: "red" }}>Changed from {compareItems[entry.id]['description']}</span>
                                                :
                                                <></>
                                        }
                                    </Tooltip>
                                </TableCell>

                                <TableCell
                                    sx={{
                                        borderBottom: "none"
                                    }}
                                >
                                    <TextField
                                        id="standard-basic" variant="standard"
                                        sx={{
                                            width: "90%"
                                        }}
                                        value={props.formData.items[index].quantity}
                                        onChange={(e) => {
                                            alterRow(index, 'quantity', e.target.value);
                                        }}
                                    />
                                    <Tooltip>
                                        {
                                            compareItems[entry.id]
                                                ?
                                                currentItems[entry.id]['quantity'] == compareItems[entry.id]['quantity']
                                                    ?
                                                    <></>
                                                    :
                                                    <span style={{ color: "red" }}>Changed from {compareItems[entry.id]['quantity']}</span>
                                                :
                                                <></>
                                        }
                                    </Tooltip>
                                </TableCell>

                                <TableCell
                                    sx={{
                                        borderBottom: "none"
                                    }}
                                >

                                    <TextField
                                        variant="standard"
                                        sx={{
                                            width: "90%"
                                        }}
                                        value={props.formData.items[index].unitCost}
                                        onChange={(e) => {
                                            alterRow(index, 'unitCost', e.target.value);
                                        }}
                                    />
                                    <Tooltip>
                                        {
                                            compareItems[entry.id]
                                                ?
                                                currentItems[entry.id]['unitCost'] == compareItems[entry.id]['unitCost']
                                                    ?
                                                    <></>
                                                    :
                                                    <span style={{ color: "red" }}>Changed from {compareItems[entry.id]['unitCost']}</span>
                                                :
                                                <></>
                                        }
                                    </Tooltip>

                                </TableCell>
                                <TableCell

                                    sx={{
                                        borderBottom: "none"
                                    }}>
                                    <CancelIcon
                                        sx={{ cursor: "pointer" }}
                                        onClick={() =>
                                            removeRow(index)
                                        }
                                    />
                                </TableCell>

                            </TableRow>
                        )}

                    </TableBody>
                </Table>


                <Button
                    className="add-item-button"
                    onClick={() => addItem()}
                    sx={{
                        margin: 2,
                        width: "40%",
                        height: "6vh",
                        backgroundColor: "black",
                        color: "white"
                    }}
                >
                    <div className="item-button-text">Add Additional Item</div>
                </Button>
            </Grid>
            <Stepper direction={"forwards"} active={true} clickFunction={nextPage} />

        </>
    )

}
