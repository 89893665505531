import React from "react";
import { Dialog } from "@mui/material";
import RegisterPageOne from "./RegisterPageOne";
import RegisterPageTwo from "./RegisterPageTwo";
import RegisterConfirm from "./RegisterConfirm";
import { useNavigate } from "react-router-dom";

import './splash.css'


export default function RegisterDialog(props) {

    const server = process.env.REACT_APP_SERVER_ADDRESS

    const [step, setStep] = React.useState(0)
    const [formData, setFormData] = React.useState({
        email: "",
        username: "",
        password: "",
        confirmPassword: "",
        firstname: "",
        lastname: "",
        country: "",
        phone: ""
    })
    const [success, setSuccess] = React.useState(false)


    const handleSubmit = async (event) => {
        fetch(`${server}/user/register`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: formData.email,
                username: formData.username,
                password: formData.password,
                firstname: formData.firstname,
                lastname: formData.lastname,
                country: formData.country,
                phone: formData.phone
            })
        })
            .then(response => {
                if (response.ok) {
                    setSuccess(true);
                    setStep(2)

                }
                else {
                    console.log('Not working in registration')
                }
            })
        event.preventDefault();
    }

    const navigate = useNavigate();

    const page = {
        0: <RegisterPageOne formData={formData} setFormData={setFormData} setStep={setStep} setSuccess={setSuccess} success={success} />,
        1: <RegisterPageTwo existingCredentials={props.existingCredentials} formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} />,
        2: <RegisterConfirm email={formData.email} />
    }


    return (

        <Dialog
            className="login-dialog"
            open={props.registerOpen}
            onClose={() => (
                props.setRegisterOpen(false),
                setStep(0),
                setFormData({
                    email: "",
                    username: "",
                    password: "",
                    confirmPassword: "",
                    firstname: "",
                    lastname: "",
                    country: "",
                    phone: ""
                })
            )}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
        >
            {
                page[step]
            }

        </Dialog>
    )
}

