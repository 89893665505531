import React from "react";
import { Box, Grid, Stack } from "@mui/material";
import { useSelector } from 'react-redux';
import LineItem from "./LineItem";
import { useMediaQuery } from "react-responsive";
import './notifications.css'



export default function NotificationsBox() {

    const [notifications, setNotifications] = React.useState(undefined);

    const unprocessedNotifications = useSelector((state) => state.notifications.notifications); // Access the 'notifications' field

    const isMobile = useMediaQuery({
        query: '(max-device-width: 900px)',
    });


    function formatDate(inputDateString) {
        const options = { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' };
        const date = new Date(inputDateString);
        return date.toLocaleString('en-GB', options);
    }

    function findDateDifference(dateToCompare) {
        // Create a Date object for the current date
        const currentDate = new Date();

        // Calculate the difference in milliseconds
        const timeDifference = currentDate - dateToCompare;

        // Calculate the number of days from milliseconds
        const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

        // Check if the difference is greater than 7 days

        if (daysDifference < 1) {
            return "day"
        }
        else if (daysDifference < 7) {
            return "week"
        }
        else if (daysDifference > 7 && daysDifference < 30) {
            return "month"
        }
        else {
            return "older"
        }
    }


    React.useEffect(() => {
        if (unprocessedNotifications && unprocessedNotifications.length > 0) {

            setNotifications(unprocessedNotifications.map((obj) => ({
                ...obj,
                datetime: formatDate(obj.datetime),
                classification: findDateDifference(new Date(obj.datetime))
            })
            ))
        }
        else {
            setNotifications(undefined)
        }

    }, [unprocessedNotifications])

    return (
        <Grid
            container
            direction={"row"}
            justifyContent="center"
            alignItems="center"
            sx={{
                paddingLeft: isMobile ? 3 : "auto",
                paddingRight: isMobile ? 3 : "auto"

            }}


        >
            <Stack direction="row"
                sx={{
                    width: "95vw",
                    marginTop: 3
                }}
            >
                <div className="notifications-header-text">Notifications</div>
            </Stack>

            <Box
                sx={{
                    width: "95%",
                    height: "100%",
                    fontSize: "1rem"
                }}
            >
                {
                    notifications
                        ?
                        <>

                            {
                                notifications.filter((i) => i.classification == "day").length > 0
                                    ?
                                    <>
                                        <div className="notification-header">Past Day</div>
                                        {notifications.filter((i) => i.classification == "day").map((i) => <LineItem i={i} />)}
                                    </>
                                    :
                                    <></>
                            }


                            {
                                notifications.filter((i) => i.classification == "week").length > 0
                                    ?
                                    <>
                                        <div className="notification-header">Past Week</div>
                                        {notifications.filter((i) => i.classification == "week").map((i) => <LineItem i={i} />)}
                                    </>
                                    :
                                    <></>
                            }
                            {
                                notifications.filter((i) => i.classification == "month").length > 0
                                    ?
                                    <>
                                        <div className="notification-header">Past Month</div>
                                        {notifications.filter((i) => i.classification == "month").map((i) => <LineItem i={i} />)}
                                    </>
                                    :
                                    <></>
                            }

                            {
                                notifications.filter((i) => i.classification == "older").length > 0
                                    ?
                                    <>
                                        <div className="notification-header">Older</div>
                                        {notifications.filter((i) => i.classification == "older").map((i) => <LineItem i={i} />)}
                                    </>
                                    :
                                    <></>
                            }

                        </>
                        :
                        <></>
                }
            </Box>
        </Grid >
    )
}