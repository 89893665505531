import React from "react"
import { Stack } from "@mui/material"
import Grid from "@mui/material/Grid";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment'
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { useMediaQuery } from "react-responsive";

import Stepper from "../Stepper";




import TextField from "@mui/material/TextField";
import { ReactReduxContext } from "react-redux";


export default function UserConfig(props) {

    { console.log(props.formData) }


    const isMobile = useMediaQuery({
        query: '(max-device-width: 900px)',
    });

    const [filled, setFilled] = React.useState(false)
    const [instUser, setInstUser] = React.useState(false)


    const dateToString = (date) => {
        if (!date || !dayjs(date).isValid()) return '';
        return dayjs(date).format('DD/MM/YYYY');
    };


    React.useEffect(() => {
        props.formData.title
            &&
            props.formData.otherParty
            &&
            props.formData.authorRole
            &&
            props.formData.dueDate
            //&&
            //(props.formData.paymentType == "oncompletion" || props.formData.upfrontPayment >= 5) //Minimum deposit of £5
            ? setFilled(true) : setFilled(false)
    }, [props.formData])

    const nextPage = () => {
        props.setEscrowStep(currentStep => currentStep + 1)
    }

    const prevPage = () => {
        props.setPage(0)
    }

    const toggleInst = (e) => {
        if (e.target.value == "no") {
            console.log(e.target.value)
            props.setInstUser(e.target.value);
            props.setFormData({ ...props.formData, inst: null });
        }
        else {
            console.log(e.target.value)
            props.setInstUser(e.target.value);
            //props.setFormData({ ...props.formData, inst: null });

        }
    }


    function formatDDMMYYYYToYYYYMMDD(dateString) {

        console.log(dateString)
        // Parse the original date string with "DD/MM/YYYY" format
        //const parsedDate = dayjs(dateString, { format: 'DD/MM/YYYY' });
        const parsedDate = dayjs(dateString, "DD/MM/YYYY")
        console.log(parsedDate)

        // Format the parsed date to "YYYY-MM-DD" format
        const formattedDate = parsedDate.format('YYYY-MM-DD');
        console.log(formattedDate)

        return formattedDate;
    }




    return (
        <>
            <Stepper direction={'backwards'} active={true} clickFunction={prevPage} />

            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
            >
                <Stack
                    direction="column"
                    sx={{
                        marginTop: isMobile ? 10 : 5,
                        width: "90%"
                    }}
                >
                    <div className="title-text">Transaction Configuration</div>
                </Stack>

                <Grid item xs={3} sx={{ width: "90%", my: 1 }}>
                    <h4>Reveal the following contact details to the other party</h4>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox disabled defaultChecked onClick={() => props.setFormData({ ...props.formData, revealUsername: !props.formData.revealUsername })} />} label="Username" />
                        <FormControlLabel control={<Checkbox defaultChecked onClick={() => props.setFormData({ ...props.formData, revealEmail: !props.formData.revealEmail })} />} label="Email Address" />
                        <FormControlLabel control={<Checkbox onClick={() => props.setFormData({ ...props.formData, revealName: !props.formData.revealName })} />} label="Name" />
                        <FormControlLabel control={<Checkbox onClick={() => props.setFormData({ ...props.formData, revealPhone: !props.formData.revealPhone })} />} label="Phone Number" />
                    </FormGroup>
                </Grid>


                <Grid item xs={3} sx={{ width: "90%", my: 1 }}>
                    <h4>Title of transaction</h4>
                    <TextField
                        id="standard-basic" variant="standard"
                        sx={{
                            width: "100%"
                        }}
                        value={props.formData.title}
                        onChange={(e) => props.setFormData({ ...props.formData, title: e.target.value })}
                    />
                </Grid>

                <Grid item xs={3} sx={{ width: "90%", my: 1 }}>
                    <h4>Email address or User
                        ID of the other party</h4>
                    <TextField
                        id="standard-basic" variant="standard"
                        sx={{
                            width: "100%"
                        }}
                        value={props.formData.otherParty}
                        onChange={(e) => props.setFormData({ ...props.formData, otherParty: e.target.value })}
                    />
                </Grid>

                <Grid item xs={3} sx={{ width: "90%", my: 1 }}>
                    <h4>Are you representing a business or institution?</h4>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        onChange={(e) => toggleInst(e)}
                        value={props.instUser}
                    >
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                    </RadioGroup>
                </Grid>


                <Grid item xs={3} sx={{ width: "90%", my: 1 }} style={props.instUser == "yes" ? { 'display': 'block' } : { 'display': 'none' }}>


                    <TextField
                        key={props.instUser}
                        id="standard-basic" variant="standard"
                        sx={{
                            width: "100%"
                        }}
                        label="Business or institution name"
                        value={props.formData.inst}
                        onChange={(e) => props.setFormData({ ...props.formData, inst: e.target.value })}
                    />
                </Grid>



                <Grid item xs={3} sx={{ width: "90%", my: 1 }}>
                    <h4>Are YOU the seller or the buyer in this transaction?</h4>
                    <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        onChange={(e) => props.setFormData({ ...props.formData, authorRole: e.target.value })}
                        value={props.formData.authorRole}
                    >
                        <FormControlLabel value="buyer" control={<Radio />} label="Buyer" />
                        <FormControlLabel value="seller" control={<Radio />} label="Seller" />
                    </RadioGroup>
                </Grid>

                <Grid item xs={3} sx={{ width: "90%", my: 1 }}>
                    <h4>What is the due date?</h4>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en'}>
                        <DesktopDatePicker
                            sx={{
                                width: "100%",
                                marginBottom: 5
                            }}
                            slotProps={{ textField: { variant: 'standard', } }}
                            //inputFormat="DD/MM/YYYY"
                            value={dayjs(formatDDMMYYYYToYYYYMMDD(props.formData.dueDate))}
                            //value={dayjs(props.formData.dueDate, { format: 'DD/MM/YYYY' }).toDate()}
                            onChange={(e) => props.setFormData({ ...props.formData, dueDate: dateToString(e.$d) })}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>


            <Stepper direction={'forwards'} active={filled} clickFunction={nextPage} />

        </>


    )

}


