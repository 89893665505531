import { Button, Dialog, Grid } from "@mui/material";
import React from "react";
import './dealprofile.css'
import Header from "../HeaderSidebar/Header";

export default function BuyerCompleteButton(props) {

    const [dialogOpen, setDialogOpen] = React.useState(false)

    const server = process.env.REACT_APP_SERVER_ADDRESS

    const complete = () => {
        fetch(`${server}/deal/complete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('user_id')}`
            },
            body: JSON.stringify({
                "dealId": props.dealData.id,
                "role": "buyer"
            })
        }
        )
            .then(resp => {
                if (resp.ok) {
                    props.setCompleteClicked(true);
                    setDialogOpen(false)

                    return resp.json()

                }
                else {
                    throw new Error(`HTTP error! status: ${resp.status}`);
                }
            })
            .catch((error) => {
                console.error('Fetch error:', error.message);
            })
    }



    const buttonSx = {
        width: "100%",
        height: "10vh",
        '&:hover': {
            backgroundColor: 'black',
        },
    }

    const dialogButtonSx = {
        width: "80%",
        height: "8vh",
        '&:hover': {
            backgroundColor: 'black',
        },
    }



    let completable

    if (props.dealData.paymentType == "oncompletion") {
        completable = props.dealData.escrowPaid && !props.dealData.buyerComplete
    }
    else {
        completable = props.dealData.upfrontPaid && !props.dealData.buyerComplete
    }



    return (

        props.dealData.buyerComplete ?

            <Button className="claim-button"
                disabled
                sx={{
                    ...buttonSx,
                    marginBottom: 2,
                    background: "lightgrey"
                }}
            >
                <div className="big-button-text">Buyer Complete</div>
            </Button>

            :

            <>
                <Dialog
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                >
                    <div className="dialog-text">
                        You are about to complete, signifying that your side of the transaction is fulfilled.
                        <p />
                        When the other party has completed the escrow funds will be released to them.
                        <p />
                        This cannot be undone.
                    </div>

                    <Grid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        direction={"column"}
                        sx={{
                            width: "100%"
                        }}
                    >
                        <Button className="claim-button"
                            onClick={() => setDialogOpen(false)}
                            sx={{
                                ...dialogButtonSx,
                                marginBottom: 2,
                                background: "black"
                            }}
                        >
                            <div className="big-button-text">Go Back</div>
                        </Button>

                        <Button className="claim-button"
                            onClick={() => complete()}
                            //onClick={() => setDialogOpen(true)}
                            sx={{
                                ...dialogButtonSx,
                                marginBottom: 5,
                                background: "black"
                            }}
                        >
                            <div className="big-button-text">Accept</div>
                        </Button>
                    </Grid>
                </Dialog>

                <Button className="claim-button"
                    disabled={!completable}
                    onClick={() => setDialogOpen(true)}
                    //onClick={() => setDialogOpen(true)}
                    sx={{
                        ...buttonSx,
                        marginBottom: 2,
                        background: completable ? "black" : "lightgrey"

                    }}
                >
                    {completable ?
                        <div className="big-button-text">Complete</div>
                        :

                        props.dealData.paymentType == "oncompletion"
                            ?
                            <div className="big-button-text">Cannot Complete until paid</div>
                            :
                            <div className="big-button-text">Cannot Complete until upfront payment made</div>

                    }
                </Button>
            </>

    )
}