import { Button, Dialog, Grid } from "@mui/material";
import React from "react";
import './dealprofile.css'
import Header from "../HeaderSidebar/Header";

export default function SellerCompleteButton(props) {

    const [dialogOpen, setDialogOpen] = React.useState(false)

    const server = process.env.REACT_APP_SERVER_ADDRESS

    const complete = () => {
        fetch(`${server}/deal/complete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('user_id')}`
            },
            body: JSON.stringify({
                "dealId": props.dealData.id,
                "role": "seller"
            })
        }
        )
            .then(resp => {
                if (resp.ok) {
                    props.setCompleteClicked(true);

                    return resp.json()

                }
                else {
                    throw new Error(`HTTP error! status: ${resp.status}`);
                }
            })
            .catch((error) => {
                console.error('Fetch error:', error.message);
            })
    }

    const buttonSx = {
        width: "100%",
        height: "10vh",
        '&:hover': {
            backgroundColor: 'black',
        },
    }

    const dialogButtonSx = {
        width: "80%",
        height: "8vh",
        '&:hover': {
            backgroundColor: 'black',
        },
    }

    return (

        props.dealData.sellerComplete ?

            <Button className="claim-button"
                disabled
                sx={{
                    ...buttonSx,
                    marginBottom: 2,
                    background: "lightgrey"
                }}
            >
                <div className="big-button-text">Items in Kashd Escrow</div>
            </Button>
            :
            <>
                <Dialog
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                >
                    <div className="dialog-text">
                        You are about to place the uploaded items in escrow, signifying that your side of the transaction is complete.
                        <p />
                        When the other party has completed the items will be released to them.
                        <p />
                        This cannot be undone.
                    </div>

                    <Grid
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                        direction={"column"}
                        sx={{
                            width: "100%"
                        }}
                    >
                        <Button className="claim-button"
                            onClick={() => setDialogOpen(false)}
                            sx={{
                                ...dialogButtonSx,
                                marginBottom: 2,
                                background: "black"
                            }}
                        >
                            <div className="big-button-text">Go Back</div>
                        </Button>

                        <Button className="claim-button"
                            onClick={() => complete()}
                            //onClick={() => setDialogOpen(true)}
                            sx={{
                                ...dialogButtonSx,
                                marginBottom: 5,
                                background: "black"
                            }}
                        >
                            <div className="big-button-text">Accept</div>
                        </Button>
                    </Grid>
                </Dialog>

                <Button className="claim-button"
                    onClick={() => setDialogOpen(true)}
                    sx={{
                        ...buttonSx,
                        marginBottom: 2,
                        background: "black"
                    }}
                >
                    <div className="big-button-text">Place Items in Kashd Escrow</div>
                </Button>
            </>
    )
}