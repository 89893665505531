import React from 'react'
import { useNavigate } from 'react-router-dom'

import { MenuItem } from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon'
import Logout from '@mui/icons-material/Logout';


export default function LogoutElement() {

    const navigate = useNavigate()

    const logout = () => {
        sessionStorage.removeItem("access_token");
        navigate('/login')
    }

    return (
        <MenuItem onClick={logout}>
            <ListItemIcon>
                <Logout fontSize="small" />
            </ListItemIcon>
            Logout
        </MenuItem>
    )

}