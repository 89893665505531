import React from "react";
import { TextField, Grid, Stack, Button } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";

import './css/PasswordReset.css'


export default function PasswordReset() {

    const [password, setPassword] = React.useState();
    const [confirmPassword, setConfirmPassword] = React.useState();
    const [userId, setUserId] = React.useState('');
    const [checkDisabled, setCheckDisabled] = React.useState(true)

    const server = process.env.REACT_APP_SERVER_ADDRESS


    //const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

    let navigate = useNavigate();

    const params = useParams();

    //Checks that new password adheres to password rules
    React.useEffect(() => {
        //const acceptable = PWD_REGEX.test(password);

        if ((password === confirmPassword)) {
            setCheckDisabled(false)
        }

    }, [password, confirmPassword])


    //Checks validity of URL token and returns ID of corresponding user
    React.useEffect(() => {
        fetch(`${server}/user/checkpasswordlink`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: params.reset_token,
                })
            })
            .then((response) => {
                if (!response.ok) {
                    navigate('/')
                }
                else {
                    return response.json()
                }
            })
            .then((resp) => setUserId(resp.data.userId))
    }, [])



    const submitPassword = () => {
        fetch(`${server}/user/resetuserpassword`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                password: password,
                userId: userId
            })
        })
            .then((response) => {
                if (response.ok) {
                    navigate('/')
                }
            })
    }


    return (
        <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            sx={{
                height: "100vh"
            }}
        >
            <Stack
                direction={"column"}
            >
                <div className="header-text">New Password</div>
                <TextField
                    onInput={e => setPassword(e.target.value)}
                    id="standard-basic" variant="standard" type="password"
                    sx={{
                        width: "60vw",
                        marginBottom: 2
                    }}
                    value={password}
                />
                <div className="header-text">Confirm Password</div>

                <TextField
                    onInput={e => setConfirmPassword(e.target.value)}
                    id="standard-basic" variant="standard" type="password"
                    sx={{
                        width: "60vw",
                    }}
                    value={confirmPassword}
                />

                <Button
                    className="login-button"
                    disabled={checkDisabled}
                    onClick={() => submitPassword()}
                    type="submit"
                    sx={{
                        width: "100%",
                        height: "10vh",
                        backgroundColor: "black",
                        marginTop: 4
                    }}
                >
                    <h3
                        className="login-text"
                        style={{
                            color: "white",
                            fontSize: "2rem"
                        }}
                    >Submit</h3>
                </Button>
            </Stack>
        </Grid>
    )
}